import { MProofOfIncome, MProofOfJob } from '@/components/leads/components/client-edit-forms/consts';
import { Joi } from '@/libs/joi';

export const financesScheme = Joi.object({
    wage: Joi.number().required(),
    primeTimeJobWage: Joi.number(),
    otherIncome: Joi.number(),
    averageMonthlyIncome: Joi.number().required(),
    proofOfIncome: Joi.string().allow(...MProofOfIncome).required(),
    proofOfJob: Joi.string().allow(...MProofOfJob).required(),
});