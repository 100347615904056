import React, { useCallback } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import get from 'lodash/get';
import { MFamilyStatus, MYesNoOptions } from '@/components/leads/components/client-edit-forms/consts';
import { TFamilyForm } from '@/components/leads/components/client-edit-forms/types';
import { InputInitials } from '@/common/ui/input-initials';
import { makeInitialsError_LEGACY } from '@/components/leads/components/client-edit-forms/validation';
import { Grid } from '@/common/ui/grid';
import { Dropdown } from '@/common/ui/dropdown';
import { Input } from '@/common/ui/input';
import { Divider } from '@/common/ui';
import { TInputHandlerChange } from '@/common/ui/input/input.types';
type PassportFormType = any & {
    [x: string]: TFamilyForm
}

type FinancesFormProps = {
    path: string
}

export function FamilyForm({ path }: FinancesFormProps) {
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
    } = useFormContext<PassportFormType>();

    const status = watch(`${ path }.status`);

    const handlerChangeInitials: TInputHandlerChange<any> = useCallback((e, { name, value }) => {
        setValue(name as any, value);
        clearErrors(name as any);
    }, [ setValue, clearErrors ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.status` }
                        render={ ({ field }) => (
                            <Dropdown
                                { ...field }
                                label='Семейное положение'
                                options={ MFamilyStatus.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                error={ (get(errors, `${ path }.status`) as FieldError)?.message }
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
                { status === 'Женат/замужем' && (
                    <>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.cohabitation` }
                                render={ ({ field }) => (
                                    <Dropdown
                                        { ...field }
                                        label='Фактическое проживание с супругом (-ой)'
                                        options={ MYesNoOptions }
                                        error={ (get(errors, `${ path }.cohabitation`) as FieldError)?.message }
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.contract` }
                                render={ ({ field }) => (
                                    <Dropdown
                                        { ...field }
                                        label='Наличие брачного контракта'
                                        options={ MYesNoOptions }
                                        error={ (get(errors, `${ path }.contract`) as FieldError)?.message }
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </>
                ) }
            </Grid.Row>

            { status === 'Женат/замужем' && (
                <>
                    <Grid.Row columns={ 1 }>
                        <Grid.Col>
                            <Divider color='black'>Данные супруга (-и)</Divider>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col fluid>
                            <Controller
                                control={ control }
                                name={ `${ path }.pairData.initials` }
                                render={ ({ field }) => (
                                    <InputInitials
                                        label='ФИО'
                                        { ...field }
                                        onChange={ (_, props) => handlerChangeInitials(null, props) }
                                        error={ makeInitialsError_LEGACY(`${ path }.pairData.initials`, errors) }
                                        value={field.value || {} as any}
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.pairData.birthday` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='text'
                                        label='Дата рождения'
                                        mask='99.99.9999'
                                        error={ (get(errors, `${ path }.pairData.birthday`) as FieldError)?.message }
                                        placeholder='дд.мм.гггг'
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.pairData.phone` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='tel'
                                        label='Мобильный телефон'
                                        mask='+7 (999) 999-99-99'
                                        placeholder='+7 (000) 000-00-00'
                                        error={ (get(errors, `${ path }.pairData.phone`) as FieldError)?.message }
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </>
            ) }
        </Grid>
    );
}
