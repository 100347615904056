import React from 'react';
import { AccordionItem } from './accordion-item';
import styles from './accordion.module.scss';

type AccordionProps = {
    items: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function Accordion({ items }: AccordionProps): React.ReactElement {
    return (
        <div className={ styles.accordion }>
            { items.map(({ title, content }, index) => (
                <AccordionItem key={ index } title={ title } content={ content }/>
            )) }
        </div>
    );
}