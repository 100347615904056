import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import cn from 'classnames';

import './images-uploader.style.scss';
import omit from 'lodash/omit';
import { PreviewImage } from '@/common/ui/preview-image';
import { transformUrlParams } from '@/utils';
import { TFile } from '@/components/leads/pladge-object-types';
import { Progress } from '@/common/progress';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';
import { Icon, Tooltip } from '@/common/ui';


type TImagesUploaderProps = {
    value: TFile[];
    uploadURL: string;
    itemURL: string;
    maxFileSize?: number;
    onUploadStart?: () => void;
    onUploadFinished?: () => void;
}

type TStateFile = (File & { fileData?: TFile });

export const ImagesUploader: React.FC<TImagesUploaderProps> = ({
    value,
    uploadURL,
    itemURL,
    maxFileSize = 10,
    onUploadStart,
    onUploadFinished,
}) => {
    const [ files, setFiles ] = useState<TStateFile[]>([]);
    const [ previews, setPreviews ] = useState<string[]>([]);
    const [ upload, setUpload ] = useState<any>({});

    const { fetch: fetchDelete } = useApiClient({
        method: 'delete',
        url: itemURL,
        successMessage: 'Изображение удалено',
    });

    React.useEffect(() => {
        if (value) {
            for (const file of value) {
                axiosPrivate.get(
                    transformUrlParams(itemURL, { fileName: file.fileName }),
                    {
                        responseType: 'arraybuffer',
                    },
                ).then(({ data }) => {
                    setFiles((current) => [
                        ...current,
                        {
                            name: file.originFileName,
                            type: file.mimeType,
                            size: file.size,
                            fileData: file,
                        } as TStateFile,
                    ]);
                    const blob = new Blob([ data ], { type: file.mimeType });
                    setPreviews((current) => [
                        ...current,
                        window.URL.createObjectURL(blob),
                    ]);
                });
            }
        }
    }, [ itemURL, value ]);

    const handlerUploadProgress = React.useCallback((file) => (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (percentCompleted < 100) {
            setUpload((current) => ({
                ...current,
                [file.name]: {
                    complete: false,
                    progress: percentCompleted,
                    error: null,
                },
            }));
        }
    }, []);

    const uploadImage = React.useCallback(async (file: File) => {
        setUpload((current) => ({
            ...current,
            [file.name]: {
                complete: false,
                progress: 0,
                error: null,
            },
        }));
        if (onUploadStart) {
            onUploadStart();
        }

        const data = new FormData();
        data.append('file', file);
        try {
            const response = await axiosPrivate.post(
                uploadURL,
                data,
                {
                    onUploadProgress: handlerUploadProgress(file),
                },
            );
            setFiles((current) => current.map((item) => {
                if (item.name === file.name) {
                    return Object.assign(item, {
                        fileData: response.data,
                    });
                }
                return item;
            }));
        } catch (err) {
            setUpload((current) => ({
                ...current,
                [file.name]: {
                    complete: true,
                    progress: 0,
                    error: 'Ошибка загрузки изображения',
                },
            }));
            return
        }

        if (onUploadStart) {
            onUploadFinished();
        }

        setUpload((current) => ({
            ...current,
            [file.name]: {
                complete: true,
                progress: 0,
                error: null,
            },
        }));
        setTimeout(() => {
            setUpload((current) => omit(current, file.name));
        }, 2000);
    }, [ onUploadStart, uploadURL, handlerUploadProgress, onUploadFinished ])

    const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
            if (files.some((c) => c.name === file.name)) {
                continue;
            }
            setFiles((current) => [
                ...current,
                file,
            ]);
            setPreviews((current) => [
                ...current,
                window.URL.createObjectURL(file),
            ]);
            if (file.size > (maxFileSize * 1024 * 1024)) {
                setUpload((current) => ({
                    ...current,
                    [file.name]: {
                        complete: true,
                        progress: 0,
                        error: `Размер файла не должен превышать ${ maxFileSize }Мб`,
                    },
                }));
            } else if (!file.type.startsWith('image/')) {
                setUpload((current) => ({
                    ...current,
                    [file.name]: {
                        complete: true,
                        progress: 0,
                        error: `Некорректный формат файла`,
                    },
                }));
            } else {
                await uploadImage(file);
            }
        }
    }, [ files, uploadImage, maxFileSize ]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        noClick: true,
        onDrop,
    });

    const handlerClickRemove = (index) => async () => {
        const file = files[index];
        await fetchDelete(null, {
            url: transformUrlParams(itemURL, { fileName: file.fileData.fileName }),
        })
        setFiles((c) => c.filter((f, i) => i !== index));
        setPreviews((c) => c.filter((p, i) => i !== index));
    };

    const handlerClickOpen = () => {
        open();
    };

    return (
        <div  {...getRootProps()} className={cn('images-uploader', { '-drag-active': isDragActive })}>
            <input {...getInputProps()} />
            <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        <div className='images-uploader-remove' onClick={handlerClickRemove(index)}>
                            <Icon name='trash'/>
                        </div>
                        <PreviewImage src={previews[index]}>
                            <div
                                className='images-uploader-preview'
                                style={{ backgroundImage: `url(${ previews[index] })` }}
                            />
                        </PreviewImage>
                        { file.name } ({ Math.round(file.size / 1024) }kb)
                        {upload[file.name] && (
                            <div className='images-uploader-upload'>
                                {!upload[file.name].complete && !upload[file.name].error && (
                                    <Progress
                                        percent={upload[file.name].progress}
                                        size='tiny'
                                        color='yellow'
                                        indicating
                                    />
                                )}
                                {upload[file.name].complete && upload[file.name].error && (
                                    <Tooltip
                                        content={upload[file.name].error}
                                        position='top center'
                                        trigger={(ref) =>(
                                            <Icon
                                                ref={ref}
                                                name='timer'
                                                color='red'
                                                size='huge'
                                            />
                                        )}
                                        inverted
                                    />
                                )}
                                {upload[file.name].complete && !upload[file.name].error && (
                                    <Icon
                                        name='circle-check'
                                        color='green'
                                        size='huge'
                                    />
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className='images-uploader-placeholder' onClick={handlerClickOpen}>
                Нажмите или перетащите изображения в эту область
            </div>
        </div>
    );
}
