import React from 'react';
import { InputInitials } from '@/common/ui/input-initials';
import { makeInitialsError_LEGACY } from '@/components/leads/components/client-edit-forms/validation';
import { Input } from '@/common/ui/input';
import { Button, Grid } from '@/common/ui';


export const ChildrenForm = ({ index, fields, errors, onChange, onRemove }) => (
    <Grid stackable>
        <Grid.Row columns={15}>
            <Grid.Col colspan={10}>
                <InputInitials
                    label='ФИО'
                    name={`family.children.${ index }.initials`}
                    error={ makeInitialsError_LEGACY(`family.children.${ index }.initials`, errors) }
                    value={fields.initials || {}}
                    onChange={onChange}
                    required
                />
            </Grid.Col>
            <Grid.Col colspan={4}>
                <Input
                    type='text'
                    label='Дата рождения'
                    name={`family.children.${ index }.birthday`}
                    error={errors[`family.children.${ index }.birthday`]}
                    mask='99.99.9999'
                    value={fields.birthday}
                    onChange={onChange}
                    placeholder='дд.мм.гггг'
                    autoComplete='off'
                    required
                />
            </Grid.Col>
            <Grid.Col colspan={1}>
                <Button
                    className='mt-5'
                    onClick={onRemove}
                    icon='xmark'
                    color='red'
                />
            </Grid.Col>
        </Grid.Row>
    </Grid>
);
