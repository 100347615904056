import React, { useCallback, useState } from 'react';

import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { set } from 'lodash';
import { UIIconButton } from 'finbox-ui-kit';
import { formatMoney, transformUrlParams } from '@/utils';
import { API_URLS, EActiveType } from '@/consts';
import { CadastralNumberRequestList } from '@/common/cadastral-number/cadastral-number-request-list';
import { ObjectType, RealtyType } from '@/components/leads/components/client-edit-forms/consts';
import { map2Options } from '@/utils/map-to-options';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { Grid } from '@/common/ui/grid';
import { Input } from '@/common/ui/input';
import { Dropdown } from '@/common/ui/dropdown';
import { InputAddress } from '@/common/ui/input-address';
import { Block, Button, Divider } from '@/common/ui';


export const RealtyActiveForm = ({ fields, onChange, onRemove, errors }) => {
    const [ rosreestrObjectIndex, setRosreestrObjectIndex ] = useState<number>(null);
    const [ lastChanged, setLastChanged ] = useState<'cadastral' | 'address'>('cadastral');
    const [ cadastralList, setCadastralList ] = useState({
        count: 0,
        data: [],
    });

    const { loading: isSearchLoading, fetch: fetchSearch } = useApiClient({
        method: 'post',
        url: API_URLS.EGRN.CADASTRAL_NUMBER,
    });

    const { loading: isCadastralDetailLoading, fetch: fetchCadastralDetail } = useApiClient({
        url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, {}),
    });

    const isLoading = isSearchLoading || isCadastralDetailLoading;

    const handlerClickAddObject = React.useCallback(() => {
        onChange(null, {
            name: 'actives.realty',
            value: [
                ...fields,
                {
                    address: null,
                    area: null,
                },
            ],
        });
    }, [ fields, onChange ]);

    const handlerCLickRemoveObject = (index: number) => () => {
        onChange(null, {
            name: 'actives.realty',
            value: fields.filter((o, i) => i !== index),
        });
    };

    const handlerLocalChange = React.useCallback((index): any => (e, { name, value, checked }) => {
        const _updated = cloneDeep(fields);
        set(_updated, `${ index }.${ name }`, checked === undefined ? value : checked);

        onChange(e, {
            name: 'actives.realty',
            value: _updated,
        });

        if (name === 'address') {
            setLastChanged('address');
        } else if (name === 'cadNumber') {
            setLastChanged('cadastral');
        }
    }, [ fields, onChange ]);

    const updateFromCadastralDetail = useCallback(async (cadastralNumber: string, objectIndex: number) => {
        const data = await fetchCadastralDetail(null, {
            url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
        });
        const [ info ] = data.elements;
        const _updated = {
            ...fields[objectIndex],
            address: info.address,
            area: parseFloat(info.area),
            levelFloor: parseInt(info.levelFloor),
            objType: info.objType,
            cadNumber: info.cadNumber,
            purpose: info.purpose,
            status: info.status === '1',
        };

        onChange(null, {
            name: 'actives.realty',
            value: fields.map((i, idx) => idx === objectIndex ? _updated : i),
        });
    }, [ fetchCadastralDetail, fields, onChange ]);

    const searchCadastralByAddress = useCallback(async (address, objectIndex) => {
        const response = await fetchSearch({
            address,
        });
        if (response.count === 0) {
            toast.error('Кадастровый номер не найден');
        } else if (response.count === 1) {
            const [ item ] = response.data;
            await updateFromCadastralDetail(item.cadnum, objectIndex);
        } else {
            setCadastralList(response);
        }
    }, [ fetchSearch, updateFromCadastralDetail ]);

    const handleClickRosreestr = React.useCallback((index) => async () => {
        setRosreestrObjectIndex(index);
        if (!fields[index].address && !fields[index].cadNumber) {
            toast.error('Для поиска требуется указать адрес или кадастровый номер');
            return;
        }
        if (lastChanged === 'cadastral' && fields[index].cadNumber) {
            await updateFromCadastralDetail(fields[index].cadNumber, index);
        } else {
            await searchCadastralByAddress(fields[index].address.data, index);
        }
    }, [ fields, lastChanged, updateFromCadastralDetail, searchCadastralByAddress ]);

    const handlerCloseCadastralList = React.useCallback(() => {
        setCadastralList(null);
    }, []);

    const handlerClickCadastralListItem = useCallback(async (cn: string) => {
        await updateFromCadastralDetail(cn, rosreestrObjectIndex);
        setCadastralList(null);
    }, [ updateFromCadastralDetail, rosreestrObjectIndex ]);

    return (
        <Block
            title='Недвижимость'
            buttons={ [ { icon: 'xmark', onClick: onRemove } ] }
            className={ `active-${ EActiveType.REALTY }` }
        >
            <Grid className='--no-margin-inputs'>
                <Grid.Row columns={ 1 }>
                    <Grid.Col>
                        { fields.map((realtyObject, index) => (
                            <Grid key={ index } stackable>
                                { fields.length > 1 && (
                                    <Grid.Row columns={ 1 } className={ fields.length > 1 && index > 0 ? 'mt1' : null }>
                                        <Grid.Col>
                                            <Divider data-object={ `object-${ index }` }>
                                                Объект { index + 1 }
                                                { index !== 0 && (
                                                    <UIIconButton
                                                        icon='trash'
                                                        color='red'
                                                        className='cursor-pointer ml-5'
                                                        onClick={ handlerCLickRemoveObject(index) }
                                                    />
                                                ) }
                                            </Divider>
                                        </Grid.Col>
                                    </Grid.Row>
                                ) }
                                <Grid.Row columns={ 3 }>
                                    <Grid.Col>
                                        <Input
                                            type='text'
                                            label='Кадастровый номер'
                                            name='cadNumber'
                                            error={ errors.cadNumber }
                                            value={ realtyObject.cadNumber }
                                            onChange={ handlerLocalChange(index) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 2 }>
                                        <div style={ { display: 'flex', gap: '1em', alignItems: 'center' } }>
                                            <div style={ { flex: 1 } }>
                                                <InputAddress
                                                    label='Адрес'
                                                    name='address'
                                                    error={ errors[`pledgeObjects.${ index }.address`] }
                                                    value={ realtyObject.address }
                                                    onChange={ handlerLocalChange(index) }
                                                    required
                                                />
                                            </div>
                                            <Button
                                                className='mt-5'
                                                icon='magnifying-glass'
                                                onClick={ handleClickRosreestr(index) }
                                                disabled={ isLoading }
                                                loading={ isLoading }
                                            />
                                        </div>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row columns={ 3 } className='pt0 pb0'>
                                    <Grid.Col>
                                        <Dropdown
                                            label='Статус объекта'
                                            name='status'
                                            error={ errors.status }
                                            value={ realtyObject.status }
                                            options={ [
                                                {
                                                    text: 'Актуально',
                                                    value: true,
                                                },
                                                {
                                                    text: 'Не актуально',
                                                    value: false,
                                                },
                                            ] }
                                            onChange={ handlerLocalChange(index) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Dropdown
                                            label='Тип объекта'
                                            name='objType'
                                            error={ errors.objType }
                                            value={ realtyObject.objType }
                                            options={ Object.entries(ObjectType).map(([ code, name ]) => ({
                                                text: name,
                                                value: code,
                                            })) }
                                            onChange={ handlerLocalChange(index) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Dropdown
                                            label='Назначение'
                                            name='purpose'
                                            error={ errors.purpose }
                                            value={ realtyObject.purpose }
                                            options={ map2Options(RealtyType) }
                                            onChange={ handlerLocalChange(index) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Input
                                            type='number'
                                            label='Площадь, кв.м'
                                            name='area'
                                            error={ errors.area }
                                            value={ realtyObject.area ? realtyObject.area : null }
                                            onChange={ handlerLocalChange(index) }
                                            postfix='кв.м'
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Input
                                            type='number'
                                            label='Этаж'
                                            name='levelFloor'
                                            error={ errors.levelFloor }
                                            value={ realtyObject.levelFloor ? realtyObject.levelFloor : null }
                                            onChange={ handlerLocalChange(index) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Input
                                            type='number'
                                            label='Доход от сдачи в аренду руб/мес.'
                                            name='rentPrice'
                                            error={ errors.rentPrice }
                                            value={ realtyObject.rentPrice }
                                            onChange={ handlerLocalChange(index) }
                                            postfix='руб.'
                                            renderValue={ (value) => formatMoney(value, false) }
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        )) }
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row className='pt0' columns={ 1 }>
                    <Grid.Col>
                        <Button
                            icon='plus'
                            className='mt1'
                            content='Добавить объект'
                            onClick={ handlerClickAddObject }
                            size='small'
                        />
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <CadastralNumberRequestList
                loading={ isCadastralDetailLoading }
                address={ fields[rosreestrObjectIndex]?.address?.data }
                data={ cadastralList }
                onClose={ handlerCloseCadastralList }
                onClick={ handlerClickCadastralListItem }
                clientId={ fields.id }
            />
        </Block>
    );
};
