import React from 'react';
import cn from 'classnames';
import { TLabelComponent, TLabelGroupProps, LabelProps } from './label';
import styles from './label.module.scss';


const Label: TLabelComponent = React.forwardRef<HTMLSpanElement, LabelProps>(function Label({
    className,
    children,
    content,
    hover,
    color = 'grey',
}, ref) {
    return (
        <span ref={ ref } className={ cn(styles.label, className, {
            [`-${ color }`]: color,
            [styles.labelHover]: hover,
        }) }>
            { children || content }
        </span>
    );
});

export function LabelGroup({ className, children, onClick }: TLabelGroupProps) {
    return (
        <div className={ cn(styles.labelGroup, className) } onClick={ onClick }>{ children }</div>
    );
}

Label.Group = LabelGroup;

export { Label };