import React, { useRef } from 'react';
import cn from 'classnames';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { Icon } from '@/common/ui';
import styles from './accordion.module.scss';

type AccordionItemProps = {
    title: string;
    content: React.ReactNode;
};

export function AccordionItem({ title, content }: AccordionItemProps): React.ReactElement {
    const textRef = useRef<HTMLParagraphElement | null>(null);
    const { on, toggle } = useToggle();

    return (
        <div className={ styles.accordionItem } key={ title }>
            <div className={ cn(styles.accordionItemInner, { [styles.accordionItemOpen]: on }) }>
                <div className={ styles.accordionItemTitle } onClick={ () => toggle() }>
                    <span>{ title }</span>
                    <Icon
                        className={ styles.accordionItemCaret }
                        name='chevron-down'
                    />
                </div>
                <div
                    className={ styles.accordionItemContentWrapper }
                    style={ {
                        '--text-height': `${ textRef.current?.clientHeight }px`,
                    } }
                >
                    <div
                        ref={ textRef }
                        className={ styles.accordionItemContent }
                    >
                        { content }
                    </div>
                </div>
            </div>
        </div>
    );
}