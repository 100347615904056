import { TPrimeTimeJobCompanyForm } from '@/components/leads/components/client-edit-forms/types';
import { addressScheme } from '@/components/leads/components/client-edit-forms/schemas/address.scheme';
import { Joi } from '@/libs/joi';

export const primeTimeJobCompanyScheme = Joi.object<TPrimeTimeJobCompanyForm>({
    name: Joi.string().required(),
    inn: Joi.string().required(),
    ogrn: Joi.string(),
    address: addressScheme.required(),
    phone: Joi.string().required(),
    jobType: Joi.string().required(),
    startDate: Joi.string().required(),
});