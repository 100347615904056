import React from 'react';
import { Outlet } from 'react-router';
import { useAtomValue } from 'jotai';
import { Footer } from '@/common/footer';
import TopMenu from '@/components/top-menu';
import { AuthUser } from '@/state/auth.state';


const AppLayout = () => {
    const user = useAtomValue(AuthUser);
    if (!user) return null;
    return (
        <main>
            <TopMenu isAdmin={ user.admin } user={ user }/>
            <div className='main-block'>
                <Outlet />
            </div>
            <Footer />
        </main>
    );
};

export default AppLayout;
