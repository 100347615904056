import React, { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { OptionsList, TOption } from '@/common/ui/options-list/options-list';
import { Icon } from '@/common/ui/icon';
import { useToggle } from '@/utils/hooks';
import { Popup } from '@/common/ui/popup';
import { extractString } from '@/utils';
import styles from './options-list.module.scss';


type OptionsListOptionProps = {
    index: number
    option: TOption
    selected: boolean
    onSelect: (option: TOption, index: number) => void
    onClick: (option: TOption, index: number) => void
    onEnter: (option: TOption, index: number) => void
    sub: boolean
    onSubOpen: (index: number) => void
    onSubClose: () => void
};

export const OptionsListOption = React.memo(function OptionsListOption({
    index,
    option,
    selected,
    onSelect,
    onClick,
    onEnter,
    sub,
    onSubOpen,
    onSubClose,
}: OptionsListOptionProps) {
    const ref = useRef(null);

    const { on: open, toggle: toggleOpen } = useToggle();

    const openPopup = useCallback(() => {
        toggleOpen(true);
        onSubOpen(index);
    }, [ index, onSubOpen, toggleOpen ]);

    const closePopup = useCallback(() => {
        toggleOpen(false);
        onSubClose();
    }, [ onSubClose, toggleOpen ]);

    function handlerClick() {
        if (option.notSelectable) {
            return;
        }
        if (option.items) {
            openPopup();
            return;
        }
        onSelect(option, index);
        onClick && onClick(option, index);
    }

    function handlerSelect(option: TOption) {
        closePopup();
        onSelect(option, index);
    }

    const handlerArrows = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'ArrowRight': {
                if (open || !selected || !option.items) {
                    return;
                }
                e.preventDefault();
                openPopup();
            }
                break;
            case 'ArrowLeft': {
                if (!open) {
                    return;
                }
                e.preventDefault();
                closePopup();
            }
                break;

            case 'Enter': {
                e.preventDefault();
                if (!selected || option.notSelectable) {
                    return;
                }
                if (!option.items) {
                    onSelect(option, index);
                    onEnter(option, index);
                    return;
                }
                if (!open) {
                    openPopup();
                }
            }
                break;
            default:
                return;
        }
    }, [ closePopup, index, onEnter, onSelect, open, openPopup, option, selected ]);

    useEffect(() => {
        window.addEventListener('keydown', handlerArrows);
        return () => {
            window.removeEventListener('keydown', handlerArrows);
        };
    }, [ open, handlerArrows ]);

    return (
        <li
            ref={ ref }
            role='option'
            className={ cn(styles.optionsListOption, {
                [styles.optionsListOptionSelected]: selected,
                [styles.optionsListOptionDisabled]: option.disabled,
                [styles.optionsListOptionOpened]: open,
            }) }
            title={ extractString(option.text) }
        >
            <div className={ styles.optionsListOptionInner } onMouseDown={ handlerClick }>
                { option.picked && (<Icon type='light' name='check' color='green'/>) }
                <span className={ styles.optionsListOptionText }>
                    { option.text }
                </span>
                { option.items && (
                    <Icon
                        className={ styles.optionsListOptionCaret }
                        type='light'
                        name='chevron-right'
                    />
                ) }
            </div>
            { option.items && (
                <Popup
                    targetRef={ ref }
                    open={ open }
                    position='right top'
                    strategy={ sub ? 'relative' : 'fixed' }
                    onClickOutside={ closePopup }
                    onClose={ closePopup }
                >
                    <OptionsList
                        active={ open }
                        options={ option.items }
                        onSelect={ handlerSelect }
                        sub
                    />
                </Popup>
            ) }
        </li>
    );
});