import { TFUITopMenuItem } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';
import { TUser } from '@/types/manager';
import { MUserRole, UserRole } from '@/consts';

export function buildTopMenu(user: TUser) {
    if (!user.roles?.length) {
        return [];
    }
    if (user.roles.length === 1) {
        return menuStructureByRole[user.role];
    }
    return [
        ...menuStructureByRole[user.role],
        ...user.roles
            .filter((i) => i !== user.role)
            .map((role) => ({
                name: MUserRole.get(role),
                items: menuStructureByRole[role],
            })),
    ];
}


const menuStructureByRole: Record<UserRole, TFUITopMenuItem[]> = {
    [UserRole.admin]: [
        {
            name: 'Клиенты',
            to: '/clients',
            counters: [ 'clientsNew' ],
            items: [
                {
                    name: 'Клиенты',
                    to: '/clients',
                    counters: [ 'clientsNew' ],
                },
                {
                    name: 'Одобрения',
                    to: '/approves/',
                    counters: [ 'approvesNew', 'approves' ],
                    items: [
                        {
                            to: '/approves/finbox',
                            name: 'Предварительно одобрены TG',
                            counters: [ 'approvesNew' ],
                        },
                        {
                            to: '/approves/all',
                            name: 'Одобрения по клиентам',
                            counters: [ 'approves' ],
                        },
                    ],
                },
            ],
        },
        {
            name: 'Аналитика',
            to: '/analytics/',
        },
        {
            name: 'Кредиторы',
            items: [
                {
                    to: '/potential-creditors/',
                    name: 'Потенциальные кредиторы',
                },
                {
                    to: '/rosreestr-reports/',
                    name: 'Выписки ЕГРН',
                },
                {
                    to: '/creditors/',
                    name: 'Действующие кредиторы',
                },
                {
                    name: 'ЕГРН кредиторы',
                    to: '/egrn-organizations',
                },
                {
                    name: 'Бот кредиторы',
                    to: '/bot-creditors',
                },
            ],
        },
        {
            name: 'TG бот',
            counters: [ 'finboxRegistrations' ],
            items: [
                {
                    name: 'Регистрации',
                    to: '/finbox/registrations',
                    counters: [ 'finboxRegistrations' ],
                },
                {
                    name: 'Кредиторы',
                    to: '/finbox/creditors',
                },
                {
                    name: 'Рассылки',
                    to: '/finbox/mailings',
                },
                {
                    name: 'Статистика',
                    to: '/finbox/stats',
                },
            ],
        },
        {
            name: 'Транзиты',
            items: [
                {
                    to: '/transits/list/',
                    name: 'Список',
                },
                {
                    to: '/transits/',
                    name: 'Сводка',
                },
                {
                    to: '/transits/financial-transactions/',
                    name: 'Финансы',
                },
                {
                    to: '/transits/loans/',
                    name: 'Кредиты',
                },
            ],
        },
        {
            name: 'Настройки',
            items: [
                {
                    name: 'Сотрудники',
                    items: [
                        {
                            to: '/settings/users/',
                            name: 'Управление',
                        },
                        {
                            to: '/settings/users/order-rules/',
                            name: 'Распределение заявок',
                        },
                        {
                            to: '/settings/work-schedule',
                            name: 'График работы',
                        },
                    ],
                },
                {
                    to: '/settings/rosreestr/',
                    name: 'Росреестр',
                    items: [
                        {
                            to: '/settings/rosreestr/accounts/',
                            name: 'Аккаунты',
                        },
                        {
                            to: '/settings/rosreestr/check-lists/',
                            name: 'Списки проверок',
                        },
                    ],
                },
                {
                    name: 'SMS',
                    items: [
                        {
                            to: '/settings/sms/templates/',
                            name: 'Шаблоны',
                        },
                        {
                            to: '/settings/sms/events/',
                            name: 'События',
                        },
                    ],
                },
                {
                    to: '/settings/contract-templates/',
                    name: 'Шаблоны документов',
                },
                {
                    to: '/settings/agreement/',
                    name: 'Соглашение об обработке',
                },
                {
                    to: '/settings/',
                    name: 'Общие настройки',
                },
                {
                    to: '/settings/cron-tasks',
                    name: 'Задачи',
                },
            ],
        },
        {
            name: 'Отчёты',
            items: [
                {
                    name: 'Статусы',
                    to: '/reports/statuses/',
                },
                {
                    name: 'Сделки',
                    items: [
                        {
                            name: 'Список',
                            to: '/contracts/',
                        },
                        {
                            name: 'Транзиты',
                            to: '/reports/transits/',
                        },
                    ],
                },
                {
                    name: 'Анализ рекламы',
                    to: '/reports/adv/',
                    items: [
                        {
                            name: 'Сравнение площадок',
                            to: '/reports/adv/platforms/',
                        },
                        {
                            name: 'Эффективность',
                            to: '/reports/adv/',

                        },
                        {
                            name: 'UTM метки',
                            to: '/reports/adv/utm',
                        },
                    ],
                },
                {
                    name: 'Сотрудники',
                    items: [
                        {
                            name: 'Отчёт о закрытии смены',
                            to: '/reports/shift-closure/',
                        },
                        {
                            name: 'Отчёт о рабочем времени',
                            to: '/reports/users-activity/',
                        },
                        {
                            to: '/settings/users/disable_history/',
                            name: 'История отключений',
                        },
                    ],
                },
                {
                    name: 'Не подтвержденные номера',
                    to: '/reports/not-verified-numbers/',
                },
                {
                    name: 'Причины удаления',
                    to: '/reports/deleted-clients-reasons/',
                },
                {
                    name: 'Статистика по выпискам',
                    to: '/reports/telegram-bot-stats/',
                },
                {
                    name: 'Статистика по продуктам',
                    to: '/reports/products/',
                },
                {
                    name: 'SMS',
                    items: [
                        {
                            name: 'Конверсия',
                            to: '/reports/sms-conversion/',
                        },
                        {
                            name: 'Отправленные',
                            to: '/reports/sms-sent/',
                        },
                    ],
                },
                {
                    name: 'Контроль',
                    items: [
                        {
                            name: 'Сделки',
                            to: '/contracts-control',
                        },
                        {
                            name: 'Агентское вознаграждение',
                            to: '/contracts-agency-rewards/',
                        },
                        {
                            name: 'Подписанные документы',
                            to: '/signed-documents/',
                        },
                    ],
                },
                {
                    name: 'Сделки по кредиторам',
                    to: '/reports/assignee-creditors-contracts/',
                },
            ],
        },
        {
            name: 'Ещё',
            items: [
                {
                    name: 'Отправленные заявки',
                    to: '/creditors_orders/',
                },
                {
                    name: 'Корзина',
                    to: '/garbage/',
                },
                {
                    name: 'Проверка юр лица',
                    to: '/search-organization',
                },
                {
                    name: 'Цифры в слова',
                    to: '/number-to-words',
                },
                {
                    name: 'Калькулятор времени рекламы',
                    to: '/settings/adv-time-calculator',
                },
            ],
        },
    ],
    [UserRole.accountant]: [
        {
            name: 'Контроль сделок',
            to: '/contracts-control',
        },
        {
            name: 'Контроль агентских',
            to: '/contracts-agency-rewards/',
        },
        {
            name: 'Документы',
            to: '/signed-documents/',
        },
        {
            to: '/creditors/',
            name: 'Кредиторы',
        },
        {
            to: '/settings/users/',
            name: 'Сотрудники',
        },
        {
            name: 'Ещё',
            items: [
                {
                    name: 'Проверка юр лица',
                    to: '/search-organization',
                },
                {
                    name: 'Цифры в слова',
                    to: '/number-to-words',
                },
            ],
        },
    ],
    [UserRole.manager]: [
        {
            name: 'Клиенты',
            to: '/clients',
            counters: [ 'clientsNew' ],
        },
        {
            name: 'Одобрения',
            to: '/approves/',
            counters: [ 'approvesNew', 'approves' ],
            items: [
                {
                    to: '/approves/finbox',
                    name: 'Предварительно одобрены TG',
                    counters: [ 'approvesNew' ],
                },
                {
                    to: '/approves/all',
                    name: 'Одобрения по клиентам',
                    counters: [ 'approves' ],
                },
            ],
        },
        {
            name: 'Аналитика',
            to: '/analytics/',
        },
        {
            name: 'Кредиторы',
            items: [
                {
                    to: '/potential-creditors/',
                    name: 'Потенциальные кредиторы',
                },
                {
                    to: '/rosreestr-reports/',
                    name: 'Выписки ЕГРН',
                },
                {
                    to: '/creditors/',
                    name: 'Действующие кредиторы',
                },
                {
                    name: 'ЕГРН кредиторы',
                    to: '/egrn-organizations',
                },
            ],
        },
        {
            name: 'Отчёты',
            items: [
                {
                    name: 'Статусы',
                    to: '/reports/statuses/',
                },
                {
                    name: 'Сделки по кредиторам',
                    to: '/reports/assignee-creditors-contracts/',
                },
                {
                    name: 'Контроль агентских',
                    to: '/contracts-agency-rewards/',
                },
                {
                    name: 'Причины удаления',
                    to: '/reports/deleted-clients-reasons/',
                },
            ],
        },
        {
            name: 'Ещё',
            items: [
                {
                    name: 'Отправленные заявки',
                    to: '/creditors_orders/',
                },
                {
                    name: 'Сделки',
                    to: '/contracts/',
                },
                {
                    name: 'Корзина',
                    to: '/garbage/',
                },
                {
                    name: 'Проверка юр лица',
                    to: '/search-organization',
                },
                {
                    name: 'Цифры в слова',
                    to: '/number-to-words',
                },
            ],
        },
    ],
    [UserRole.creditors_manager]: [
        {
            to: '/rosreestr-reports/',
            name: 'Выписки',
        },
        {
            to: '/potential-creditors/',
            name: 'Потенциальные',
        },
        {
            to: '/creditors/',
            name: 'Кредиторы',
        },
        {
            name: 'TG регистрации',
            to: '/finbox/registrations',
            counters: [ 'finboxRegistrations' ],
        },
        {
            name: 'TG Кредиторы',
            to: '/finbox/creditors',
        },
        {
            name: 'Отчёты',
            items: [
                {
                    name: 'ЕГРН кредиторы',
                    to: '/egrn-organizations',
                },
                {
                    name: 'Сделки по кредиторам',
                    to: '/reports/assignee-creditors-contracts/',
                },
                {
                    name: 'Статистика по выпискам',
                    to: '/reports/telegram-bot-stats/',
                },
                {
                    name: 'Контроль агентских',
                    to: '/contracts-agency-rewards/',
                },
            ],
        },
        {
            name: 'Ещё',
            items: [
                {
                    name: 'Проверка юр лица',
                    to: '/search-organization',
                },
                {
                    name: 'Цифры в слова',
                    to: '/number-to-words',
                },
            ],
        },
    ],
    [UserRole.analytics]: [
        {
            name: 'UTM метки',
            to: '/reports/adv/utm',
        },
        {
            name: 'Эффективность рекламы',
            to: '/reports/adv/',

        },
        {
            name: 'Аналитика',
            to: '/analytics/',
        },
        {
            name: 'Сравнение площадок',
            to: '/reports/adv/platforms/',
        },
        {
            name: 'Соглашение об обработке',
            to: '/settings/agreement/',
        },
        {
            name: 'Ещё',
            items: [
                {
                    name: 'Калькулятор времени рекламы',
                    to: '/settings/adv-time-calculator',
                },
            ],
        },
    ],
    [UserRole.additional]: [
        {
            name: 'Бот кредиторы',
            to: '/bot-creditors',
        },
    ],
    [UserRole.hr]: [
        {
            name: 'Аналитика',
            to: '/analytics/',
        },
        {
            name: 'Сотрудники',
            to: '/settings/users/',
        },
        {
            name: 'Отчёт о рабочем времени',
            to: '/reports/users-activity/',
        },
        {
            to: '/settings/work-schedule',
            name: 'График работы',
        },
    ],
};