import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import { TUser } from '@/types/manager';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { useAuthContext } from '@/context/auth.context';
import { Button, FilePicker, Modal, useModal } from '@/common/ui';
import { useToggle } from '@/utils/hooks';
import { uid } from '@/utils';
import styles from './user-avatar.module.scss';

type UserAvatarProps = {
    user: TUser;
    onChange?: () => void;
    editable?: boolean;
    small?: boolean;
};

export function UserAvatar({ user, onChange, editable, small }: UserAvatarProps): React.ReactElement {
    const editor = useRef(null);
    const { on, toggle } = useToggle();
    const [ newAvatarFile, setNewAvatarFile ] = useState<File>(null);
    const [ scale, setScale ] = useState(1);
    const [ key, setKey ] = useState(uid());
    const { fetchUser } = useAuthContext();
    const { show, props } = useModal({
        onClose() {
            setNewAvatarFile(null);
        },
    });

    const { fetch } = useApiClient({
        method: 'patch',
        url: `/users/${ user.id }/avatar`,
    });

    useEffect(() => {
        setTimeout(() => {
            toggle(true);
        }, 300);
    }, [ toggle ]);

    function handlerPickFile(files: File[]) {
        setNewAvatarFile(files[0]);
    }

    async function handlerUpload() {
        const canvas = editor.current.getImageScaledToCanvas();
        const blob: Blob | null = await new Promise((resolve) => {
            canvas.toBlob((blob: (Blob | null)) => {
                resolve(blob);
            }, 'image/png', 1);
        });
        await fetch({}, {
            attachments: [ [ 'avatar', new File([ blob ], "name", { type: 'image/png' }) ] ],
        });
        await fetchUser();
        onChange && onChange();
        props.onClose();
        setKey(uid());
    }

    return (
        <div className={ styles.userAvatar }>
            <div className={ styles.userAvatarImage }>
                <div className={ cn(styles.userAvatarImageInitials, { [styles.userAvatarImageInitialsShowed]: on }) }>
                    <span>{ `${ user.surname[0] }${ user.name[0] }` }</span>
                </div>
                <div className={ styles.userAvatarImagePic } style={ {
                    backgroundImage: `url(/api/v2/users/${ user.id }/avatar${ small ? '/small' : '' }${editable ? `?cache=${ key }` : ''})`,
                } }/>
            </div>

            { editable && (
                <div className={ styles.userAvatarEditButton }>
                    <Button
                        icon='pencil'
                        content='Редактировать'
                        onClick={ () => show() }
                    />
                </div>
            ) }
            <Modal { ...props } size='tiny' showCloseButton>
                <Modal.Header>Редактирование аватара</Modal.Header>
                <Modal.Content>
                    { newAvatarFile && (
                        <div className='p2 text-center'>
                            <div style={ { transform: 'scale(0.4)', height: 480, marginTop: -160, marginLeft: -150 } }>
                                <AvatarEditor
                                    ref={ editor }
                                    image={ newAvatarFile }
                                    width={ 800 }
                                    height={ 800 }
                                    border={ 0 }
                                    borderRadius={ 400 }
                                    color={ [ 255, 255, 255, 0.6 ] } // RGBA
                                    scale={ scale }
                                    rotate={ 0 }
                                />
                            </div>
                            <input
                                type='range'
                                min={ 1 }
                                max={ 5 }
                                step={ 0.1 }
                                style={ { width: '100%' } }
                                onChange={ (e) => setScale(parseFloat(e.target.value)) }
                                value={ scale }
                            />
                        </div>
                    ) }

                    <FilePicker
                        accept={ {
                            'image/jpeg': [],
                            'image/png': [],
                        } }
                        onChange={ handlerPickFile }
                        maxFiles={ 1 }
                        buttonText='Выберите изображение jpg или png'
                        showPickedFiles={ false }
                        value={ newAvatarFile ? [ newAvatarFile ] : [] }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Сохранить'
                        onClick={ handlerUpload }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ () => props.onClose() }
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
}