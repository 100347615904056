import { useQuery } from '@tanstack/react-query';
import { API_URLS } from '@/consts';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';
import {
    TDictionaries,
    TDictionaryTypeMap,
} from './use-dictionary.d';


export function useDictionary<
    TDictName extends TDictionaries = TDictionaries,
    TDictValue extends TDictionaryTypeMap<TDictName> = TDictionaryTypeMap<TDictName>,
>(
    name: TDictName,
    defaultValue?: TDictionaryTypeMap<TDictName>,
): [ TDictValue, { loading: boolean, isCache: boolean } ] {
    const { data, isLoading } = useQuery({
        queryKey: [ 'dictionary', name ],
        queryFn: async () => {
            const response = await axiosPrivate.get(API_URLS.DICTIONARIES[name]);
            if (!response) {
                return;
            }
            if ('data' in response.data) {
                return response.data.data;
            } else if (Array.isArray(response.data)) {
                return response.data as TDictValue;
            } else {
                throw new Error('Unrecognized dictionary response');
            }
        },
        placeholderData: defaultValue,
        networkMode: 'offlineFirst',
        staleTime: 60 * 60 * 1000,
        gcTime: 60 * 60 * 1000,
    });
    return [ data, { loading: isLoading, isCache: false } ];
}
