import React from 'react';
import { InputInitials } from '@/common/ui/input-initials';
import { makeInitialsError_LEGACY } from '@/components/leads/components/client-edit-forms/validation';
import { Input } from '@/common/ui/input';
import { Grid } from '@/common/ui/grid';
import { Button } from '@/common/ui';


export const ContactForm = ({ index, fields, errors, onChange, onRemove }) => (
    <Grid stackable>
        <Grid.Row columns={15}>
            <Grid.Col colspan={10}>
                <InputInitials
                    label='ФИО'
                    name={`contacts.${ index }.initials`}
                    error={ makeInitialsError_LEGACY(`contacts.${ index }.initials`, errors) }
                    value={fields.initials || {}}
                    onChange={onChange}
                    required
                />
            </Grid.Col>
            <Grid.Col colspan={4}>
                <Input
                    type='tel'
                    label='Мобильный телефон'
                    name={`contacts.${ index }.phone`}
                    error={errors[`contacts.${ index }.phone`]}
                    mask='+7 (999) 999-99-99'
                    value={fields.phone}
                    onChange={onChange}
                    placeholder='+7 (000) 000-00-00'
                    required
                />
            </Grid.Col>
            <Grid.Col>
                <Button
                    className='mt-5'
                    onClick={onRemove}
                    icon='xmark'
                />
            </Grid.Col>
        </Grid.Row>
    </Grid>
);
