import { addressScheme } from '@/components/leads/components/client-edit-forms/schemas/address.scheme';
import { Joi } from '@/libs/joi';

export const companyScheme = Joi.object({
    name: Joi.string().required(),
    phone: Joi.phone().required(),
    address: addressScheme.required(),
    inn: Joi.inn().required(),
    isEqualAddresses: Joi.boolean().required(),
    factAddress: addressScheme.required(),
    ogrn: Joi.string(),
    activityType: Joi.string().required(),
    scope: Joi.string().required(),
    employeesCount: Joi.string().required(),
});