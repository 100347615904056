import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { UIOverlayModal } from 'finbox-ui-kit';
import { FORMAT } from 'finbox-ui-kit/consts';
import { formatMoney, transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { ObjectType } from '@/components/leads/components/client-edit-forms/consts';
import { CadastralReportsList } from '@/common/cadastral-number/cadastral-reports-list';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { Button, PlaceholderValue, Table } from '@/common/ui';

export const CadastralNumberDetail = ({ open, cadastralNumber, clientId, onClose, index }: any) => {
    const [ data, setData ] = useState({
        count: 0,
        elements: [],
    });

    const { loading: isLoading, fetch } = useApiClient({
        url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
    });

    useEffect(() => {
        if (open) {
            fetch(
                null,
                {
                    url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
                },
            ).then((response) => setData(response));
        }
    }, [ open, fetch, cadastralNumber ]);

    const handlerClickClose = useCallback(() => {
        setData({
            count: 0,
            elements: [],
        });
        onClose();
    }, [ onClose ]);

    if (!data.elements) {
        return null;
    }
    const [ info ] = data.elements;

    return (
        <UIOverlayModal
            open={ open }
            onClose={ handlerClickClose }
            index={ index }
        >
            <UIOverlayModal.Header>Информация по объекту { cadastralNumber }</UIOverlayModal.Header>
            <UIOverlayModal.Body className='relative'>
                <Table striped noBorder hover>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan={ 2 }>
                                <h4 className='mb0'>Общая информация</h4>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={ 300 }>Кадастровый номер</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.cadNumber }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Статус объекта</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.status }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Дата постановки на кадастровый учет</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.regDate ? DateTime.fromMillis(info.regDate).toFormat(FORMAT.DATE) : null }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Дата обновления информации</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.infoUpdateDate ? DateTime.fromMillis(info.infoUpdateDate).toFormat(FORMAT.DATE) : null }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={ 2 }>
                                <h4 className='mb0'>Характеристика объекта</h4>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Тип объекта</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { ObjectType[info?.objType] }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Этаж</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.levelFloor }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Площадь, кв.м</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.area }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Кадастровая стоимость</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { formatMoney(info?.cadCost) }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Дата определения стоимости</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.cadCostDeterminationDate ? DateTime.fromMillis(info.cadCostDeterminationDate).toFormat(FORMAT.DATE) : null }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Дата внесения стоимости</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { info?.cadCostRegistrationDate ? DateTime.fromMillis(info.cadCostRegistrationDate).toFormat(FORMAT.DATE) : null }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Назначение</Table.Cell>
                            <Table.Cell>
                                <PlaceholderValue loading={ isLoading } fallback='-'>
                                    { (info?.rights || []).length > 0 ? 'Зарегистрированы' : 'Не зарегистрированы' }
                                </PlaceholderValue>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={ 2 }>
                                <h4 className='mb0'>Права и ограничения</h4>
                            </Table.Cell>
                        </Table.Row>
                        { (info?.rights || []).map((right) => (
                            <Table.Row key={ right.rightNumber }>
                                <Table.Cell>Право</Table.Cell>
                                <Table.Cell>
                                    <PlaceholderValue loading={ isLoading } fallback='-'>
                                        {
                                            [
                                                `${ right.rightNumber } (${ right.rightTypeDesc })`,
                                                right.rightRegDate ? ` от ${ DateTime.fromMillis(right.rightRegDate).toFormat(FORMAT.DATE) }` : '',
                                            ].join(' ')
                                        }
                                    </PlaceholderValue>
                                </Table.Cell>
                            </Table.Row>
                        )) }

                        { (info?.encumbrances || []).map((encumb) => (
                            <Table.Row key={ encumb.rightNumber } className='color-red'>
                                <Table.Cell>Ограничение</Table.Cell>
                                <Table.Cell>
                                    <PlaceholderValue loading={ isLoading } fallback='-'>
                                        { [
                                            encumb.encumbranceNumber,
                                            encumb.typeDesc ? `(${ encumb.typeDesc })` : '',
                                            encumb.startDate ? `от ${ DateTime.fromMillis(encumb.startDate).toFormat(FORMAT.DATE) }` : '',
                                        ].join(' ') }
                                    </PlaceholderValue>
                                </Table.Cell>
                            </Table.Row>
                        )) }
                        <Table.Row>
                            <Table.Cell colSpan={ 2 }>
                                <h4 className='mb0'>Устаревшие номера</h4>
                            </Table.Cell>
                        </Table.Row>

                        { (info?.oldNumbers || []).map((number) => (
                            <Table.Row key={ number.numValue }>
                                <Table.Cell>{ number.numType }</Table.Cell>
                                <Table.Cell>
                                    <PlaceholderValue loading={ isLoading } fallback='-'>
                                        { number.numValue }
                                    </PlaceholderValue>
                                </Table.Cell>
                            </Table.Row>
                        )) }
                    </Table.Body>
                </Table>

                { clientId && (
                    <div className='mt2'>
                        <CadastralReportsList
                            cadastralNumber={ cadastralNumber }
                            clientId={ clientId }
                        />
                    </div>
                ) }
            </UIOverlayModal.Body>
            <UIOverlayModal.Footer>
                <Button
                    type='button'
                    content='Закрыть'
                    onClick={ handlerClickClose }
                />
            </UIOverlayModal.Footer>
        </UIOverlayModal>
    );
};
