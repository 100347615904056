import { useCallback, useState } from 'react';
import { useUpdate } from '@/utils/hooks/use-update';

type useToggleOptions = {
    onChange?: (newState: boolean) => void;
}

export function useToggle(initial = false, options: useToggleOptions = {}) {
    const [ on, setOn ] = useState(initial);

    useUpdate(() => {
        if (options.onChange) {
            options.onChange(on);
        }
    }, [ on ]);

    const toggle = useCallback((value?: boolean) => {
        if (value === undefined) {
            setOn((p) => !p);
        } else {
            setOn(value);
        }
    }, []);

    return {
        on,
        toggle,
    };
}