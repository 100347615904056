import React, { Ref, useEffect, useMemo, useRef } from "react";
import cn from 'classnames';
import { TColor, TSize } from '@/types/common';
import { TPopupPosition } from '@/common/ui/popup/popup';
import { isDefined } from '@/utils/is-defined';
import { Popup } from '@/common/ui';
import { useToggle } from '@/utils/hooks';
import styles from './tooltip.module.scss';

const colorsMap: Record<TColor, string> = {
    white: styles.tooltipWhite,
    red: styles.tooltipRed,
    orange: styles.tooltipOrange,
    yellow: styles.tooltipYellow,
    olive: styles.tooltipOlive,
    green: styles.tooltipGreen,
    teal: styles.tooltipTeal,
    blue: styles.tooltipBlue,
    violet: styles.tooltipViolet,
    purple: styles.tooltipPurple,
    pink: styles.tooltipPink,
    brown: styles.tooltipBrown,
    grey: styles.tooltipGrey,
    black: styles.tooltipBlack,
};

export function Tooltip({
    content,
    trigger,
    position = 'top center',
    inverted,
    noPadding,
    open: controlledOpen,
    onClose,
    size = 'small',
    color = 'grey',
    on = 'hover',
    offset = 5,
}: TooltipProps) {
    const triggerRef = useRef<HTMLElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const { on: open, toggle } = useToggle(controlledOpen);

    useEffect(() => {
        const element = triggerRef.current;
        function handlerEnter() {
            toggle(true);
        }
        function handlerLeave() {
            toggle(false);
        }

        function handleClickOutside(event: MouseEvent) {
            if (
                triggerRef.current && !(triggerRef.current as any).contains(event.target)
                && tooltipRef.current && !(tooltipRef.current as any).contains(event.target)
            ) {
                if (!isDefined(controlledOpen)) {
                    toggle(false);
                }
                if (onClose) {
                    onClose();
                }
            }
        }
        if (!isDefined(controlledOpen)) {
            if (on === 'hover') {
                element?.addEventListener('mouseenter', handlerEnter);
                element?.addEventListener('mouseleave', handlerLeave);
            } else {
                element?.addEventListener('click', handlerEnter);
            }
        }
        document.addEventListener("click", handleClickOutside);

        return () => {
            if (!isDefined(controlledOpen)) {
                if (on === 'hover') {
                    // element?.removeEventListener('mouseenter', handlerEnter);
                    // element?.removeEventListener('mouseleave', handlerLeave);
                } else {
                    element?.removeEventListener('click', handlerEnter);
                }
            }
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ controlledOpen, on, onClose, toggle ]);

    useEffect(() => {
        if(isDefined(controlledOpen) && triggerRef) {
            toggle(controlledOpen);
        }
    }, [ controlledOpen, toggle ]);

    const _trigger = useMemo(() => trigger(triggerRef), [ trigger ])

    return (
        <>
            { _trigger }
            <Popup
                className={styles.tooltipPopup}
                targetRef={ triggerRef }
                strategy='fixed'
                position={ position }
                minWidth={ 0 }
                open={ open }
                offset={ offset }
                style={{ zIndex: 9999 }}
                portal='overlay-modal-portal'
            >
                <div
                    ref={ tooltipRef }
                    className={ cn(
                        styles.tooltip,
                        colorsMap[color],
                        {
                            [styles.tooltipInverted]: inverted,
                            [styles.tooltipNoPadding]: noPadding,
                            [`-${ size }`]: size,
                        }) }
                >
                    { content }
                </div>
            </Popup>
        </>
    );
}

type TooltipProps = {
    content: React.ReactNode
    trigger: (ref: Ref<any> | undefined) => React.ReactElement
    position?: TPopupPosition
    inverted?: boolean
    noPadding?: boolean
    offset?: number
    on?: 'click' | 'hover'
    color?: TColor
    size?: TSize
    open?: boolean
    onClose?: () => void
}
