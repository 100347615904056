import React from 'react';
import cn from 'classnames';
import { TButtonsGroupProps } from '@/common/ui/button/button.types';
import styles from './button.module.scss';

export function ButtonsGroup({ children, className, switcher }: TButtonsGroupProps) {
    return (
        <div className={cn(
            styles.buttonsGroup,
            {
                [styles.buttonsGroupSwitcher]: switcher,
            },
            className,
        )}>
            { children }
        </div>
    )
}