import React, { useCallback, useState } from 'react';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { validateFullOrder } from '@/components/leads/components/client-edit-forms/validation';
import { transformUrlParams } from '@/utils';
import { API_URLS, PRODUCTION } from '@/consts';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { ECreditFormType } from '@/components/leads/components/client-edit-forms/consts';
import { ClientSendRequisites } from '@/components/leads/components/requisites/client-send-requisites';
import { TClient } from '@/types/lead';
import { ClientDocuments } from '@/components/leads/components/documents';
import { TContractTemplate } from '@/types/contract-template';
import { Button, Modal, useModal } from '@/common/ui';


interface IClientDocumentsContext {
    loading: boolean;
    downloadPledgeCard: ({
        client,
        user,
        hiddenContacts,
    }) => Promise<void>;
    sendRequisites: (client: TClient) => void;
    downloadDocument: (client: TClient, document: TContractTemplate) => void;
}


const ClientDocumentsContext = React.createContext<IClientDocumentsContext>({
    loading: false,
    downloadPledgeCard: () => null,
    sendRequisites: () => null,
    downloadDocument: () => null,
});

export const useClientDocumentsContext = () => React.useContext(ClientDocumentsContext);


export const ClientDocumentsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const [ downloadErrors, setDownloadErrors ] = useState<Record<string, string>>(null);
    const [ sendRequisitesClient, setSendRequisitesClient ] = useState<TClient>(null);
    const [ downloadTemplateClient, setDownloadTemplateClient ] = useState<TClient>(null);
    const [ downloadTemplateDocument, setDownloadTemplateDocument ] = useState<TContractTemplate>(null);
    const [ clientId, setClientId ] = useState<number>();

    const { isLoading: isLoadingDownloadOrder, download: downloaderPledgeObjectCard } = useDownloadFile();
    const { isLoading: isLoadingDownloadDocuments, download: downloadPledgeObjectDocuments } = useDownloadFile();

    const { show, hide, props } = useModal({
        onClose: () => {
            setDownloadErrors(null);
        },
    });

    const detectFormType = (client: any) => {
        if (!client?.client?.products) {
            return null;
        }
        if (client.client.products.includes(58)) {
            return ECreditFormType.MORTGAGE;
        } else if (client.client.products.includes(1)) {
            return ECreditFormType.CREDIT;
        }
        return ECreditFormType.LOAN;
    };

    const downloadPledgeCard: IClientDocumentsContext['downloadPledgeCard'] = useCallback(async ({
        client,
        user,
        hiddenContacts,
    }) => {
        const formType = detectFormType(client);
        const { isValid, errors } = validateFullOrder(client, formType, user);
        if (isValid) {
            toast.info('Файл скачивается');
            await downloaderPledgeObjectCard(
                transformUrlParams(API_URLS.LEADS.PLEDGE_OBJECT_DOWNLOAD, { id: client.id }) + `?hiddenContacts=${ hiddenContacts }`,
                !PRODUCTION,
            );
            if (
                (client?.pledgeObjects || []).some((i: any) => i.documentsImages.length)
                && confirm('Загрузить документы?')
            ) {
                await downloadPledgeObjectDocuments(
                    transformUrlParams(API_URLS.LEADS.PLEDGE_OBJECT_DOWNLOAD_DOCUMENTS, { id: client.id }),
                );
            }
        } else {
            setClientId(client.id);
            setDownloadErrors(errors);
            show();
        }
    }, [ downloadPledgeObjectDocuments, downloaderPledgeObjectCard, show ]);


    const handlerClickFixErrors = React.useCallback(() => {
        navigate(`/leads/${ clientId }/profile/edit/client`);
        hide();
    }, [ clientId, hide, navigate ]);

    const sendRequisites: IClientDocumentsContext['sendRequisites'] = useCallback((client) => {
        setSendRequisitesClient(client);
    }, []);

    const handlerCloseSendRequisites = useCallback(() => {
        setSendRequisitesClient(null);
    }, []);

    const downloadDocument: IClientDocumentsContext['downloadDocument'] = useCallback((client, document) => {
        setDownloadTemplateClient(client);
        setDownloadTemplateDocument(document);
    }, []);

    const handlerCloseDownloadTemplate = useCallback(() => {
        setDownloadTemplateClient(null);
        setDownloadTemplateDocument(null);
    }, []);

    return (
        <ClientDocumentsContext.Provider value={ {
            loading: isLoadingDownloadOrder || isLoadingDownloadDocuments,
            downloadPledgeCard,
            sendRequisites,
            downloadDocument,
        } }>
            { children }
            <Modal { ...props } size='small'>
                <Modal.Header>Ошибки заполнения</Modal.Header>
                <Modal.Content>
                    Для скачивания необходимо исправить следующие ошибки:
                    <ul>
                        { Object.entries(downloadErrors || {}).map(([ key, error ]) => (
                            <li key={ key }>
                                <b>{ i18next.t(key, key) as string }:</b> { error }
                            </li>
                        )) }
                    </ul>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Исправить' onClick={ handlerClickFixErrors } primary/>
                    <Button content='Закрвть' onClick={ () => props.onClose() }/>
                </Modal.Actions>
            </Modal>
            <ClientSendRequisites
                open={ !!sendRequisitesClient }
                onClose={ handlerCloseSendRequisites }
                clientId={ sendRequisitesClient && sendRequisitesClient.client.id }
                phone={ sendRequisitesClient && sendRequisitesClient.client.phone }
                contactPhone={ sendRequisitesClient && sendRequisitesClient.client.contactPhone }
                email={ sendRequisitesClient && sendRequisitesClient.client.email }
                clientInitials={ sendRequisitesClient && sendRequisitesClient.client.initials }
            />
            <ClientDocuments
                open={ !!downloadTemplateClient }
                client={ downloadTemplateClient }
                document={ downloadTemplateDocument }
                onClose={ handlerCloseDownloadTemplate }
                onSuccess={ handlerCloseDownloadTemplate }
            />
        </ClientDocumentsContext.Provider>
    );
};
