import React, { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import { useToggle } from '@/utils/hooks';
import { Icon, Popup } from '@/common/ui';
import { TSize } from '@/types/common';
import styles from './style.module.scss';


type TInfoProps = {
    children?: React.ReactNode,
    content?: React.ReactNode,
    className?: string,
    opened?: boolean,
    inverted?: boolean,
    showOnHover?: boolean,
    size?: TSize
}
export const Info: React.FC<TInfoProps> = ({
    opened,
    content,
    className,
    inverted,
    children,
    showOnHover,
    size,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { on, toggle } = useToggle();

    const handlerCLickOpen = useCallback(() => {
        toggle(true);
    }, [ toggle ]);

    useEffect(() => {
        if (opened) {
            setTimeout(handlerCLickOpen, 200);
        }
    }, [ opened, handlerCLickOpen ]);

    const handlerClickOutside = useCallback(() => {
        toggle(false);
    }, [ toggle ]);

    const handlerMouseEnter = useCallback(() => {
        if (showOnHover) {
            toggle(true);
        }
    }, [ showOnHover, toggle ]);

    const handlerMouseLeave = useCallback(() => {
        if (showOnHover) {
            toggle(false);
        }
    }, [ showOnHover, toggle ]);

    return (
        <div
            className={ cn(styles.info, className) }
            onMouseLeave={ handlerMouseLeave }
        >
            <div
                ref={ ref }
                className={ styles.infoIcon }
                onClick={ handlerCLickOpen }
                onMouseEnter={ handlerMouseEnter }
            >
                <Icon
                    name='info'
                    size='small'
                />
            </div>
            {on && (
                <Popup
                    targetRef={ ref }
                    // strategy='fixed'
                    // portal='root'
                    position='bottom center'
                    open={ on }
                    onClickOutside={ handlerClickOutside }
                    minWidth={ 0 }
                >
                    <div className={ cn(styles.infoPopup, { [styles.infoPopupInverted]: inverted, [`-${ size }`]: size }) }>
                        { content || children }
                    </div>
                </Popup>
            )}
        </div>
    );
};
