import { io } from 'socket.io-client';


// eslint-disable-next-line no-console
console.log('[Sockets] Reconnect is enabled', !import.meta.env.DEV);

export const socket = io('/sockets', {
    path: '/sockets',
    transports: [ 'websocket' ],
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 3000,
    reconnectionAttempts: !import.meta.env.DEV ? Infinity : 10,
});
