import React from 'react';
import { Placeholder } from '@/common/ui/placeholder';
import { isDefined } from '@/utils/is-defined';

type PlaceholderValueProps = {
    loading: boolean;
    children?: React.ReactNode;
    value?: any;
    fallback?: string
    placeholderClassName?: string
};

export function PlaceholderValue({ fallback, loading, value, children, placeholderClassName }: PlaceholderValueProps): React.ReactElement {
    if (loading) {
        return (<Placeholder className={ placeholderClassName }/>);
    }
    return isDefined(value || children) ? (value || children) : fallback;
}