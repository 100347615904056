import { initialsScheme } from '@/components/leads/components/client-edit-forms/schemas/initials.scheme';
import { TFamilyForm } from '@/components/leads/components/client-edit-forms/types';
import { Joi } from '@/libs/joi';

export const familyScheme = Joi.object<TFamilyForm>({
    status: Joi.string().required(),
    cohabitation: Joi.boolean().when('status', {
        is: Joi.string().valid('Женат/замужем'),
        then: Joi.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    contract: Joi.boolean().when('status', {
        is: Joi.string().valid('Женат/замужем'),
        then: Joi.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    pairData: Joi.object().when('status', {
        is: Joi.string().valid('Женат/замужем'),
        then: Joi.object({
            initials: initialsScheme.required(),
            birthday: Joi.string().required(),
            phone: Joi.phone().required(),
        }).required(),
        otherwise: Joi.allow(null).optional(),
    }),
});