import React, { useCallback, useState } from 'react';
import scrollToElement from 'scroll-to-element';

import { API_URLS, MClientAddressBasis } from '@/consts';
import { makeInitialsError_LEGACY, validateStep } from '@/components/leads/components/client-edit-forms/validation';
import { TStepProps } from '@/components/leads/components/client-edit-forms/types';
import { MEducation, MFamilyStatus, MYesNoOptions } from '@/components/leads/components/client-edit-forms/consts';
import { ContactForm } from '@/components/leads/components/client-edit-forms/components/contact-form';
import { ChildrenForm } from '@/components/leads/components/client-edit-forms/components/children-form';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputSuggestions } from '@/common/ui/input-suggestions';
import { Input } from '@/common/ui/input';
import { Checkbox } from '@/common/ui/checkbox';
import { Dropdown } from '@/common/ui/dropdown';
import { Grid } from '@/common/ui/grid';
import { InputInitials } from '@/common/ui/input-initials';
import { InputAddress } from '@/common/ui/input-address';
import { Block, Button } from '@/common/ui';


export const PersonalStep: React.FC<TStepProps> = ({
    fields,
    formType,
    handlerChange,
    nextStep,
    prevStep,
}) => {
    const [ departmentSuggestions, setDepartmentSuggestions ] = useState([]);
    const [ errors, setErrors ] = useState<any>({});

    const { loading: isLoading, fetch } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.PASSPORT,
        successMessage: null,
    });

    const handlerLocalChange = useCallback(async (e, { name, value, checked }) => {
        handlerChange(e, {
            name,
            value: checked === undefined ? value : checked,
        });

        if (name === 'family.pairData.initials') {
            setErrors((c) => ({
                ...c,
                'family.pairData.initials.name': null,
                'family.pairData.initials.surname': null,
            }));
        } else {
            setErrors((c) => ({ ...c, [name]: null }));
        }
        if (name === 'personal.passport.departmentCode') {
            if (/^[0-9]{3}-[0-9]{3}$/.test(value)) {
                const request = await fetch({
                    query: value,
                });
                setDepartmentSuggestions(request.suggestions.map((i) => ({
                    text: i.value,
                    value: i.value,
                })));
            }
        }
        if (name === 'personal.addressEqualRegistration' && checked) {
            handlerChange(e, {
                name: 'personal.address',
                value: fields.personal.passport.registrationAddress,
            });
            setErrors((c) => ({ ...c, 'personal.address': null }));
        }
        if (name === 'personal.addressEqualPledgeObject' && checked) {
            handlerChange(e, {
                name: 'personal.passport.registrationAddress',
                value: fields.pledgeObjects[0].address,
            });
            setErrors((c) => ({ ...c, 'personal.passport.registrationAddress': null }));
        }
    }, [ fetch, handlerChange, fields ]);


    const handlerSubmit = useCallback((next = false) => () => {
        const { isValid, errors } = validateStep('personal', fields, formType);
        if (!isValid) {
            setErrors(errors);
            setTimeout(() => {
                scrollToElement('.FUI-input.-error', { align: 'top', offset: -124 });
            }, 100);
            return;
        }
        nextStep(next);
    }, [ nextStep, fields, formType ]);

    const handlerAddContact = React.useCallback(() => {
        handlerChange(null, {
            name: 'contacts',
            value: [
                ...(fields.contacts || []),
                {
                    initials: {},
                    phone: '',
                },
            ],
        });
    }, [ fields.contacts, handlerChange ]);

    const handlerRemoveContact = React.useCallback((index) => () => {
        handlerChange(null, {
            name: 'contacts',
            value: fields.contacts.filter((i, idx) => idx !== index),
        });
    }, [ fields.contacts, handlerChange ]);

    const handlerAddChildren = React.useCallback(() => {
        handlerChange(null, {
            name: 'family.children',
            value: [
                ...(fields.family.children || []),
                {
                    initials: {},
                    birthday: null,
                },
            ],
        });
    }, [ fields.family.children, handlerChange ]);

    const handlerRemoveChildren = React.useCallback((index) => () => {
        handlerChange(null, {
            name: 'family.children',
            value: fields.family.children.filter((i, idx) => idx !== index),
        });
    }, [ fields.family.children, handlerChange ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Checkbox
                        label='ФИО менялись'
                        name='personal.initialsChanged'
                        checked={ fields.personal.initialsChanged }
                        onChange={ handlerLocalChange }
                    />
                </Grid.Col>
                { fields.personal.initialsChanged && (
                    <Grid.Col fluid>
                        <InputInitials
                            label='Прежние ФИО'
                            name='personal.oldInitials'
                            error={ errors['personal.oldInitials'] }
                            value={ fields.personal.oldInitials }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                ) }
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Дата рождения'
                        name='personal.passport.birthday'
                        mask='99.99.9999'
                        value={ fields.personal.passport.birthday }
                        error={ errors['personal.passport.birthday'] }
                        onChange={ handlerLocalChange }
                        placeholder='дд.мм.гггг'
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Серия и номер'
                        name='personal.passport.serialAndNumber'
                        error={ errors['personal.passport.serialAndNumber'] }
                        mask='9999 999999'
                        value={ fields.personal.passport.serialAndNumber }
                        onChange={ handlerLocalChange }
                        placeholder='0000 000000'
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Дата выдачи'
                        name='personal.passport.dateOfIssue'
                        error={ errors['personal.passport.dateOfIssue'] }
                        mask='99.99.9999'
                        value={ fields.personal.passport.dateOfIssue }
                        onChange={ handlerLocalChange }
                        placeholder='дд.мм.гггг'
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Код подразделения'
                        name='personal.passport.departmentCode'
                        error={ errors['personal.passport.departmentCode'] }
                        mask='999-999'
                        value={ fields.personal.passport.departmentCode }
                        onChange={ handlerLocalChange }
                        placeholder='000-000'
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col colspan={ 2 }>
                    <InputSuggestions
                        type='text'
                        label='Кем выдан'
                        name='personal.passport.department'
                        error={ errors['personal.passport.department'] }
                        value={ fields.personal.passport.department }
                        onChange={ handlerLocalChange }
                        suggestions={ departmentSuggestions }
                        loading={ isLoading }
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col fluid>
                    <Input
                        label='Место рождения'
                        name='personal.passport.birthplace'
                        error={ errors['personal.passport.birthplace'] }
                        value={ fields.personal.passport.birthplace }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col fluid>
                    <Checkbox
                        label='Совпадает с адресом объекта залога'
                        name='personal.addressEqualPledgeObject'
                        checked={ fields.personal.addressEqualPledgeObject }
                        disabled={ fields.pledgeObjects.length === 0 }
                        onChange={ handlerLocalChange }
                    />
                </Grid.Col>
                <Grid.Col colspan={ 2 }>
                    <InputAddress
                        label='Адрес прописки'
                        name='personal.passport.registrationAddress'
                        error={ errors['personal.passport.registrationAddress'] }
                        value={ fields.personal.passport.registrationAddress }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                        disabled={ fields.personal.addressEqualPledgeObject }
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Дата регистрации'
                        name='personal.passport.registrationDate'
                        error={ errors['personal.passport.registrationDate'] }
                        mask='99.99.9999'
                        value={ fields.personal.passport.registrationDate }
                        onChange={ handlerLocalChange }
                        placeholder='дд.мм.гггг'
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col fluid>
                    <Checkbox
                        label='Совпадает с адресом прописки'
                        name='personal.addressEqualRegistration'
                        checked={ fields.personal.addressEqualRegistration }
                        disabled={ !fields.personal.passport.registrationAddress }
                        onChange={ handlerLocalChange }
                    />
                </Grid.Col>
                <Grid.Col colspan={ 2 }>
                    <InputAddress
                        label='Адрес проживания'
                        name='personal.address'
                        error={ errors['personal.address'] }
                        value={ fields.personal.address }
                        onChange={ handlerLocalChange }
                        disabled={ fields.personal.addressEqualRegistration }
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Dropdown
                        label='Основание проживания'
                        name='personal.addressBasis'
                        error={ errors['personal.addressBasis'] }
                        value={ fields.personal.addressBasis }
                        onChange={ handlerLocalChange }
                        options={ Array.from(MClientAddressBasis).map(([ value, text ]) => ({
                            text,
                            value,
                        })) }
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='text'
                        label='СНИЛС'
                        name='personal.snils'
                        error={ errors['personal.snils'] }
                        mask='999-999-999 99'
                        value={ fields.personal.snils }
                        onChange={ handlerLocalChange }
                        placeholder='000-000-000 00'
                    />
                </Grid.Col>
                <Grid.Col>
                    <Dropdown
                        label='Образование'
                        name='workAndEducation.education'
                        error={ errors['workAndEducation.education'] }
                        value={ fields.workAndEducation.education }
                        options={ MEducation.map((item) => ({
                            text: item,
                            value: item,
                        })) }
                        onChange={ handlerChange }
                        required
                    />
                </Grid.Col>
            </Grid.Row>

            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Dropdown
                        label='Семейное положение'
                        name='family.status'
                        error={ errors['family.status'] }
                        value={ fields.family.status }
                        options={ MFamilyStatus.map((item) => ({
                            text: item,
                            value: item,
                        })) }
                        onChange={ handlerLocalChange }
                        required
                    />
                </Grid.Col>
                { fields.family.status === 'Женат/замужем' && (
                    <Grid.Col>
                        <Dropdown
                            label='Фактическое проживание с супругом (-ой)'
                            name='family.cohabitation'
                            error={ errors['family.cohabitation'] }
                            value={ fields.family.cohabitation }
                            options={ MYesNoOptions }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                ) }
                { fields.family.status === 'Женат/замужем' && (
                    <Grid.Col>
                        <Dropdown
                            label='Наличие брачного контракта'
                            name='family.contract'
                            error={ errors['family.contract'] }
                            value={ fields.family.contract }
                            options={ MYesNoOptions }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                ) }
            </Grid.Row>

            { fields.family.status === 'Женат/замужем' && (
                <Grid.Row columns={ 1 } className='mb1'>
                    <Grid.Col>
                        <Block title='Данные супруга (-и)'>
                            <Grid stackable>
                                <Grid.Row columns={ 3 }>
                                    <Grid.Col fluid>
                                        <InputInitials
                                            label='ФИО'
                                            name='family.pairData.initials'
                                            error={ makeInitialsError_LEGACY(`family.pairData.initials`, errors) }
                                            value={ fields.family.pairData.initials }
                                            onChange={ handlerLocalChange }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Input
                                            type='text'
                                            label='Дата рождения'
                                            name='family.pairData.birthday'
                                            error={ errors['family.pairData.birthday'] }
                                            mask='99.99.9999'
                                            value={ fields.family.pairData.birthday }
                                            onChange={ handlerLocalChange }
                                            placeholder='дд.мм.гггг'
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Input
                                            type='tel'
                                            label='Мобильный телефон'
                                            name='family.pairData.phone'
                                            error={ errors['family.pairData.phone'] }
                                            mask='+7 (999) 999-99-99'
                                            value={ fields.family.pairData.phone }
                                            onChange={ handlerLocalChange }
                                            placeholder='+7 (000) 000-00-00'
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Block>
                    </Grid.Col>
                </Grid.Row>
            ) }
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Кол-во несовершеннолетних детей'
                        name='family.amountOfMinorChildren'
                        error={ errors['family.amountOfMinorChildren'] }
                        value={ fields.family.amountOfMinorChildren }
                        onChange={ handlerLocalChange }
                    />
                </Grid.Col>
            </Grid.Row>
            { (fields.family.children && fields.family.children.length > 0) && (
                <Grid.Row columns={ 1 } className='mb1'>
                    <Grid.Col>
                        <Block title='Данные детей'>
                            <Grid stackable>
                                { fields.family.children.map((children, index) => (
                                    <Grid.Row key={ index } columns={ 1 }>
                                        <Grid.Col>
                                            <ChildrenForm
                                                fields={ children }
                                                index={ index }
                                                errors={ errors }
                                                onChange={ handlerLocalChange }
                                                onRemove={ handlerRemoveChildren(index) }
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                )) }
                            </Grid>
                        </Block>
                    </Grid.Col>
                </Grid.Row>
            ) }
            { !!fields.family.amountOfMinorChildren && (fields.family.children && fields.family.children.length < fields.family.amountOfMinorChildren) && (
                <Grid.Row columns={ 3 } className='mb1'>
                    <Grid.Col>
                        <Button
                            content='Добавить данные ребёнка'
                            icon='plus'
                            onClick={ handlerAddChildren }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }

            { (fields.contacts && fields.contacts.length > 0) && (
                <Grid.Row columns={ 1 } className='mb1'>
                    <Grid.Col>
                        <Block title='Контактные лица'>
                            { fields.contacts.map((contact, index) => (
                                <ContactForm
                                    key={ index }
                                    fields={ contact }
                                    index={ index }
                                    errors={ errors }
                                    onChange={ handlerLocalChange }
                                    onRemove={ handlerRemoveContact(index) }
                                />
                            )) }
                        </Block>
                    </Grid.Col>
                </Grid.Row>
            ) }
            <Grid.Row columns={ 1 }>
                <Grid.Col>
                    <Button
                        content='Добавить контактное лицо'
                        icon='plus'
                        onClick={ handlerAddContact }
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row
                className='mt1'
                columns={ 1 }
                style={ {
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    background: ' #fff',
                } }
            >
                <Grid.Col>
                    <Button
                        className='mr-5'
                        content='Сохранить и продолжить'
                        onClick={ handlerSubmit(true) }
                        primary
                    />
                    <Button
                        className='mr-5'
                        content='Сохранить'
                        onClick={ handlerSubmit() }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ prevStep }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
};
