import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { formatInitials } from 'finbox-ui-kit/utils';
import { TUser } from '@/types/manager';
import { AuthState, AuthUser } from '@/state/auth.state';
import { useConfigController } from '@/controllers/config.controller';
import { API_URLS, PRODUCTION, UserRole } from '@/consts';
import { useBeforeUnload } from '@/utils/hooks/use-before-unload';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { removeJwtToken, saveJwtToken } from '@/libs/jwt-token';
import { broadcastChannel } from '@/libs/broadcast-channel';


interface IAuthContext {
    user: TUser;
    doLogoutUser: () => Promise<void>;
    doAuthUser: (token: string) => Promise<void>;
    isLoadingCheckAuth: boolean;
    checkRights: (userField: keyof TUser | (keyof TUser)[], equal: any) => boolean;
    checkRoles: (roles: UserRole[]) => boolean;
    fetchUser: () => Promise<void>;
}


const AuthContext = React.createContext<IAuthContext>({
    user: undefined,
    doLogoutUser: () => null,
    doAuthUser: () => null,
    isLoadingCheckAuth: true,
    checkRights: () => null,
    checkRoles: () => null,
    fetchUser: () => null,
});

export const useAuthContext = () => React.useContext(AuthContext);
let wasSuccessSessionStarted = false;

export const AuthContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { fetchConfig, resetConfig } = useConfigController();
    const [ isLoadingCheckAuth, setIsLoadingCheckAuth ] = useState(true);
    const [ , setIsAuthState ] = useAtom(AuthState);
    const [ user, setAuthUser ] = useAtom(AuthUser);
    const { connect, disconnect } = useSocketsContext();

    const { fetch: fetchCheckAuth } = useApiClient({
        url: API_URLS.AUTH.CURRENT_USER,
    });

    const logout = useCallback(() => {
        disconnect();
        removeJwtToken();
        resetConfig();
        setAuthUser(null);
        setIsAuthState(false);
    }, [ disconnect, resetConfig, setAuthUser, setIsAuthState ]);

    const doLogoutUser = useCallback(async () => {
        await broadcastChannel.postMessage({
            type: 'LOGOUT',
        });
        logout();
    }, [ logout ]);

    const fetchUser = useCallback(async () => {
        const user = await fetchCheckAuth();
        if (PRODUCTION && Sentry.isInitialized) {
            Sentry.setContext('crmUserID', {
                id: user.id,
                active: user.active,
                initials: formatInitials(user),
            });
            Sentry.setTags({
                crmUserID: user.id,
                crmUserActive: user.active,
                crmUserInitials: formatInitials(user),
            });
        }
        setAuthUser(user as TUser);
        return user;
    }, [ fetchCheckAuth, setAuthUser ]);

    const auth = useCallback(async () => {
        setIsLoadingCheckAuth(true);
        let user: TUser;
        try {
            user = await fetchUser();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            await doLogoutUser();
            return;
        } finally {
            setIsLoadingCheckAuth(false);
        }
        setIsAuthState(true);
        fetchConfig()
            // eslint-disable-next-line no-console
            .then(() => console.log('[Config] success'));
        setTimeout(() => connect(user.id), 500);
        wasSuccessSessionStarted = true;
    }, [ connect, doLogoutUser, fetchConfig, fetchUser, setIsAuthState ]);

    const doAuthUser = useCallback(async (token: string) => {
        saveJwtToken(token);
        await auth();
        await broadcastChannel.postMessage({
            type: 'LOGIN',
            data: token,
        });
    }, [ auth ]);

    const handlerBCEvent = useCallback(async ({ type }) => {
        if (type === 'LOGIN') {
            await auth();
        }
        if (type === 'LOGOUT') {
            logout();
            return;
        }
    }, [ auth, logout ]);

    useEffect(() => {
        broadcastChannel.addEventListener("message", handlerBCEvent);
        return () => {
            broadcastChannel.removeEventListener("message", handlerBCEvent);
        };
    }, [ handlerBCEvent ]);

    useEffect(() => {
        async function handlerAuthFail() {
            await doLogoutUser();
            if (wasSuccessSessionStarted) {
                toast.warning('Сессия завершена');
            }
        }

        window.addEventListener("auth_failed", handlerAuthFail);
        return () => {
            window.removeEventListener("auth_failed", handlerAuthFail);
        };
    }, [ doLogoutUser ]);

    useBeforeUnload(() => {
        disconnect();
    });

    useEffect(() => {
        auth();
    }, [ auth ]);


    function checkRights(userField: keyof TUser | (keyof TUser)[], equal: any): boolean {
        if (Array.isArray(userField)) {
            return userField.some((clauseField) => user[clauseField] === equal);
        }
        return user[userField] === equal;
    }

    function checkRoles(roles: UserRole[]): boolean {
        return user.roles?.some((role) => roles.includes(role));
    }

    return (
        <AuthContext.Provider value={ {
            user,
            doLogoutUser,
            doAuthUser,
            isLoadingCheckAuth,
            checkRights,
            checkRoles,
            fetchUser,
        } }>
            { children }
        </AuthContext.Provider>
    );
};
