import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import { TBreadcrumbsProps } from './breadcrumbs';

import { Icon } from '../icon';
import styles from './breadcrumbs.module.scss';

export const Breadcrumbs: React.FC<TBreadcrumbsProps> = memo(function Breadcrumbs({ items, indexLink }) {
    return (
        <ul className={styles.breadcrumbs}>
            <li>
                <Link to={indexLink || '/'}>
                    <Icon name='house' type='sharp-solid'/>
                </Link>
            </li>
            { items.map((item, index) => (
                <li key={ index }>
                    <Link to={ item.link }>
                        { item.name }
                    </Link>
                </li>
            )) }
        </ul>
    )
});