import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { useUpdate } from '@/utils/hooks/use-update';
import { TPosition, TPopupPosition, TPopupProps, TPopupWrapperProps } from './popup';
import { calculateRelativePosition } from './relative-position';
import { calculateFixedPosition } from './fixed-position';
import styles from './popup.module.scss';


const positionClassMap: Record<TPopupPosition, string> = {
    'bottom left': styles.BottomLeft,
    'bottom right': styles.BottomRight,
    'bottom center': styles.BottomCenter,
    'top left': styles.TopLeft,
    'top right': styles.TopRight,
    'top center': styles.TopCenter,
    'right top': styles.RightTop,
    'right bottom': styles.RightBottom,
    'left top': styles.LeftTop,
    'left bottom': styles.LeftBottom,
};

export function Popup({
    strategy = 'relative',
    children,
    targetRef,
    open,
    position = 'bottom left',
    minWidth = 240,
    maxWidth,
    closeOnClickOutside = true,
    onClickOutside,
    content,
    className,
    portal,
    padded,
    style,
    render,
    offset = 0,
}: TPopupProps) {
    const popupRef = React.useRef<HTMLDivElement>(null);
    const positionGetter = useMemo(() => strategy === 'fixed' ? calculateFixedPosition : calculateRelativePosition, [ strategy ]);
    const [ topPosition, setTopPosition ] = useState<TPosition>(0);
    const [ leftPosition, setLeftPosition ] = useState<TPosition>(0);
    const [ rightPosition, setRightPosition ] = useState<TPosition>('auto');
    const [ realPosition, setRealPosition ] = useState<TPopupPosition>(position);
    const [ visible, setVisible ] = useState(false);
    const [ showed, setShowed ] = useState(false);

    useEffect(() => {
        const targetRect: DOMRect | undefined = targetRef.current?.getBoundingClientRect();
        const popupRect: DOMRect | undefined = popupRef.current?.getBoundingClientRect();
        if (popupRect) {
            const [ top, left, right, calcPosition ] = positionGetter(position, targetRect, popupRect, offset);
            setTopPosition(top);
            setLeftPosition(left);
            setRightPosition(right);
            setRealPosition(calcPosition);
        }
    }, [ offset, position, positionGetter, targetRef ]);

    useUpdate(() => {
        if (open) {
            setShowed(true);
            setTimeout(() => {
                setVisible(true);
            }, 50);
        } else {
            setVisible(false);
            setTimeout(() => {
                setShowed(false);
            }, 200);
        }
    }, [ open ]);

    useEffect(() => {
        if (showed) {
            const targetRect: DOMRect | undefined = targetRef.current?.getBoundingClientRect();
            const popupRect: DOMRect | undefined = popupRef.current?.getBoundingClientRect();
            const [ top, left, right, calcPosition ] = positionGetter(position, targetRect, popupRect, offset);
            setTopPosition(top);
            setLeftPosition(left);
            setRightPosition(right);
            setRealPosition(calcPosition);
        }
    }, [ offset, position, positionGetter, showed, targetRef ]);

    useLayoutEffect(() => {
        let resizeObserver = null;
        if (targetRef?.current && popupRef.current) {
            resizeObserver = new ResizeObserver(([ entry ]) => {
                const targetRect: DOMRect | undefined = targetRef.current?.getBoundingClientRect();
                const popupRect: DOMRect | undefined = entry.target?.getBoundingClientRect();
                const [ top, left, right, calcPosition ] = positionGetter(position, targetRect, popupRect, offset);
                setTopPosition(top);
                setLeftPosition(left);
                setRightPosition(right);
                setRealPosition(calcPosition);
            });
            resizeObserver.observe(popupRef.current);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
                resizeObserver = null;
            }
        };
    }, [ position, targetRef, offset, positionGetter ]);

    const handlerScroll = useCallback(() => {
        const targetRect: DOMRect | undefined = targetRef.current?.getBoundingClientRect();
        const popupRect: DOMRect | undefined = popupRef.current?.getBoundingClientRect();
        if (targetRect && popupRect && open) {
            const [ top, left, right, calcPosition ] = positionGetter(position, targetRect, popupRect, offset);
            if (strategy === 'fixed') {
                (popupRef.current as any).style = `top: ${top}px;left: ${left}px; right: ${right}px; min-width: ${ minWidth }px; max-width: ${ maxWidth }px;`;
                setRealPosition(calcPosition);
            } else if (top !== topPosition || left !== leftPosition) {
                setTopPosition(top);
                setLeftPosition(left);
                setRightPosition(right);
                setRealPosition(calcPosition);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ leftPosition, minWidth, open, position, strategy, targetRef, topPosition, offset ]);

    const handlerClickOutside = useCallback((e: MouseEvent) => {
        if (open && closeOnClickOutside && onClickOutside) {
            if (!targetRef.current?.contains(e.target) && !popupRef.current?.contains(e.target as Node)) {
                onClickOutside();
            }
        }
    }, [ closeOnClickOutside, onClickOutside, open, targetRef ]);

    useEffect(() => {
        window.addEventListener('scroll', handlerScroll);
        window.addEventListener('resize', handlerScroll);
        window.addEventListener('click', handlerClickOutside);
        return () => {
            window.removeEventListener('scroll', handlerScroll);
            window.removeEventListener('resize', handlerScroll);
            window.removeEventListener('click', handlerClickOutside);
        }
    }, [ handlerClickOutside, handlerScroll ]);

    const innerContent = render ? render(realPosition) : (children || content);
    const popup = (
        <div
            ref={ popupRef }
            className={ cn(styles.popup, positionClassMap[realPosition], className, {
                [styles.Open]: visible,
                [styles.Fixed]: strategy === 'fixed',
                [styles.Padded]: padded,
            }) }
            style={ {
                ...style && style,
                top: topPosition,
                left: leftPosition,
                right: rightPosition,
                minWidth,
                maxWidth,
            } }
        >
            { innerContent }
        </div>
    );

    if (!showed) {
        return null;
    }

    if (portal && strategy === 'fixed') {
        return ReactDOM.createPortal(
            popup,
            document.getElementById(portal),
        );
    }
    return popup;
}

function PopupWrapper({ children, className, style }: TPopupWrapperProps) {
    return (
        <div className={ cn(styles.popupWrapper, className) } style={ style }>
            { children }
        </div>
    );
}

Popup.Wrapper = PopupWrapper;