import React from 'react';
import cn from 'classnames';
import { TSize } from '@/types/common';
import styles from './placeholder.module.scss';

type PlaceholderProps = {
    className?: string
    size?: TSize;
};

export function Placeholder({ className, size }: PlaceholderProps): React.ReactElement {
    return (
        <div className={ cn(styles.placeholder, className, { [`-${ size }`]: size }) }/>
    );
}