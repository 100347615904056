import React from 'react';
import cn from 'classnames';
import { Icon } from '@/common/ui';
import styles from './style.module.scss';

type InfoIconProps = {
    className?: string
}
export const InfoIcon = React.forwardRef<any, InfoIconProps>(function InfoIcon({ className }, ref) {
    return (
        <Icon
            ref={ ref }
            className={ cn(styles.infoIcon, className) }
            name='info'
            size='small'
        />
    );
});