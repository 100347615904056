import React, { useCallback, useState } from 'react';
import scrollToElement from 'scroll-to-element';
import { API_URLS } from '@/consts';
import { MCompanyActivityType, MEmploymentType, MSelfEmployedType, MYesNoOptions } from '@/components/leads/components/client-edit-forms/consts';
import { validateStep } from '@/components/leads/components/client-edit-forms/validation';
import { TStepProps } from '@/components/leads/components/client-edit-forms/types';
import { CompanyForm } from '@/components/leads/components/client-edit-forms/components/company-form';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { Grid } from '@/common/ui/grid';
import { Dropdown } from '@/common/ui/dropdown';
import { InputSuggestions } from '@/common/ui/input-suggestions';
import { Input } from '@/common/ui/input';
import { Textarea } from '@/common/ui/textarea';
import { Checkbox } from '@/common/ui/checkbox';
import { InputAddress } from '@/common/ui/input-address';
import { Button, Divider } from '@/common/ui';


export const WorkAndEducationStep: React.FC<TStepProps> = ({
    fields,
    formType,
    handlerChange: rootHandlerChange,
    nextStep,
    prevStep,
}) => {
    const [ errors, setErrors ] = useState<any>({});
    const [ companySuggestions, setCompanySuggestions ] = useState([]);
    const [ companyScopeSuggestions, setCompanyScopeSuggestions ] = useState([]);
    const [ isEqualAddresses, setIsEqualAddresses ] = useState(false);

    const { loading: isLoadingOrganization, fetch: fetchOrganization } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.ORGANIZATION,
    });

    const { loading: isLoadingScope, fetch: fetchScope } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.OKVED2,
    });

    React.useEffect(() => {
        if (fields.workAndEducation.company.address && fields.workAndEducation.company.factAddress) {
            if (fields.workAndEducation.company.address.value === fields.workAndEducation.company.factAddress.value) {
                setIsEqualAddresses(true);
            }
        }
    }, [ fields.workAndEducation.company, fields.workAndEducation.company.address, fields.workAndEducation.company.factAddress ]);

    const handlerChange = React.useCallback(async (e, { name, value, option }) => {
        rootHandlerChange(e, { name, value, data: option?.data });
        setErrors((c) => ({ ...c, [name]: null }));
        switch (name) {
            case 'workAndEducation.employmentType': {
                if ([ null, 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес' ].includes(value)) {
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.activityType',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.name',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.inn',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.ogrn',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.address',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.factAddress',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.scope',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.phone',
                        value: null,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.employee.position',
                        value: null,
                    });
                    setErrors((c) => ({
                        ...c,
                        'workAndEducation.company.activityType': null,
                        'workAndEducation.company.name': null,
                        'workAndEducation.company.inn': null,
                        'workAndEducation.company.ogrn': null,
                        'workAndEducation.company.address': null,
                        'workAndEducation.company.factAddress': null,
                        'workAndEducation.company.scope': null,
                        'workAndEducation.company.phone': null,
                        'workAndEducation.employee.position': null,
                    }));
                }
                if (value !== 'Самозанятый') {
                    rootHandlerChange(e, {
                        name: 'workAndEducation.selfEmployedINN',
                        value: null,
                    });
                }
            }
                break;
            case 'workAndEducation.company.name': {
                const request = await fetchOrganization({
                    query: value,
                });
                setCompanySuggestions(request.suggestions.map((i) => ({
                    text: `${ i.value } (${ i.data.address.value })`,
                    value: i.value,
                    data: i,
                })));
                if (option?.data?.data) {
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.inn',
                        value: option.data.data.inn,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.ogrn',
                        value: option.data.data.ogrn,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.address',
                        value: option.data.data.address.unrestricted_value,
                    });
                    rootHandlerChange(e, {
                        name: 'workAndEducation.company.factAddress',
                        value: option.data.data.address.unrestricted_value,
                    });
                    setErrors((c) => ({
                        ...c,
                        'workAndEducation.company.inn': null,
                        'workAndEducation.company.ogrn': null,
                        'workAndEducation.company.address': null,
                    }));
                }
            }
                break;
            case 'workAndEducation.company.scope': {
                const request = await fetchScope({
                    query: value,
                });
                setCompanyScopeSuggestions(request.suggestions
                    .filter((i) => i.data.kod?.split('.')?.length < 3)
                    .map((i) => ({
                        text: i.value,
                        value: i.value,
                        data: i,
                    })));
            }
                break;
        }
    }, [ fetchOrganization, fetchScope, rootHandlerChange ]);

    const handlerSubmit = useCallback((next = false) => () => {
        const { isValid, errors } = validateStep('workAndEducation', fields, formType);
        if (!isValid) {
            setErrors(errors);
            setTimeout(() => {
                scrollToElement('.FUI-input.-error', { align: 'top', offset: -124 });
            }, 100);
            return;
        }
        nextStep(next);
    }, [ nextStep, fields, formType ]);

    const handlerChangeEqualAddresses = React.useCallback(() => {
        if (!isEqualAddresses && fields.workAndEducation.company.address) {
            setIsEqualAddresses(true);
            rootHandlerChange(null, {
                name: 'workAndEducation.company.factAddress',
                value: fields.workAndEducation.company.address,
            });
        } else {
            setIsEqualAddresses(false);
        }
    }, [ isEqualAddresses, fields, rootHandlerChange ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 } className='pb0'>
                <Grid.Col>
                    <Dropdown
                        label='Тип занятости'
                        name='workAndEducation.employmentType'
                        error={ errors['workAndEducation.employmentType'] }
                        value={ fields.workAndEducation.employmentType }
                        options={ MEmploymentType.map((item) => ({
                            text: item,
                            value: item,
                        })) }
                        onChange={ handlerChange }
                        required
                    />
                </Grid.Col>
                { [ 'Самозанятый', 'Неофициальный бизнес' ].includes(fields.workAndEducation.employmentType) && (
                    <>
                        <Grid.Col>
                            <InputSuggestions
                                type='text'
                                label='Сфера деятельности'
                                suggestions={ MSelfEmployedType.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                name='workAndEducation.selfEmployedType'
                                value={ fields.workAndEducation.selfEmployedType }
                                error={ errors['workAndEducation.selfEmployedType'] }
                                onChange={ handlerChange }
                            />
                        </Grid.Col>
                        { fields.workAndEducation.employmentType === 'Самозанятый' && (
                            <Grid.Col>
                                <Input
                                    type='text'
                                    label='ИНН'
                                    name='workAndEducation.selfEmployedINN'
                                    value={ fields.workAndEducation.selfEmployedINN }
                                    error={ errors['workAndEducation.selfEmployedINN'] }
                                    autoComplete='off'
                                    mask='999999999999'
                                    placeholder='000000000000'
                                    onChange={ handlerChange }
                                    required
                                />
                            </Grid.Col>
                        ) }
                    </>
                ) }
            </Grid.Row>
            { ![ null, 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес' ].includes(fields.workAndEducation.employmentType) && (
                <>
                    <Grid.Row columns={3} className='pb0'>
                        <Grid.Col colspan={2}>
                            <InputSuggestions
                                type='text'
                                label='Название организации'
                                name='workAndEducation.company.name'
                                error={ errors['workAndEducation.company.name'] }
                                value={ fields.workAndEducation.company.name }
                                onChange={ handlerChange }
                                suggestions={ companySuggestions }
                                loading={ isLoadingOrganization }
                                placeholder='Начните вводить название или ИНН'
                                filtering={ false }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                type='tel'
                                label='Рабочий телефон'
                                name='workAndEducation.company.phone'
                                error={ errors['workAndEducation.company.phone'] }
                                mask='+7 (999) 999-99-99'
                                value={ fields.workAndEducation.company.phone }
                                onChange={ handlerChange }
                                placeholder='+7 (000) 000-00-00'
                                required
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={3} className='p0'>
                        <Grid.Col colspan={2}>
                            <InputAddress
                                label='Юридический адрес'
                                name='workAndEducation.company.address'
                                error={ errors['workAndEducation.company.address'] }
                                value={ fields.workAndEducation.company.address }
                                onChange={ handlerChange }
                                autoComplete='off'
                                required
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                type='number'
                                label='ИНН'
                                name='workAndEducation.company.inn'
                                error={ errors['workAndEducation.company.inn'] }
                                value={ fields.workAndEducation.company.inn }
                                onChange={ handlerChange }
                                required
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={3} className='p0'>
                        <Grid.Col colspan={2}>
                            <InputAddress
                                label='Фактический адрес'
                                className='mb_5'
                                name='workAndEducation.company.factAddress'
                                error={ errors['workAndEducation.company.factAddress'] }
                                value={ fields.workAndEducation.company.factAddress }
                                onChange={ handlerChange }
                                disabled={ isEqualAddresses }
                                autoComplete='off'
                                required
                            />
                            <Checkbox
                                className='mt1'
                                label='Совпадает с юридическим'
                                checked={ isEqualAddresses }
                                disabled={ !fields.workAndEducation.company.address }
                                onChange={ handlerChangeEqualAddresses }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                type='number'
                                label='ОГРН'
                                name='workAndEducation.company.ogrn'
                                error={ errors['workAndEducation.company.ogrn'] }
                                value={ fields.workAndEducation.company.ogrn }
                                onChange={ handlerChange }
                                required
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 } className='p0'>
                        <Grid.Col>
                            <Dropdown
                                label='Вид деятельности организации'
                                name='workAndEducation.company.activityType'
                                error={ errors['workAndEducation.company.activityType'] }
                                value={ fields.workAndEducation.company.activityType }
                                options={ MCompanyActivityType.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                onChange={ handlerChange }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <InputSuggestions
                                type='text'
                                label='Сфера деятельности компании'
                                name='workAndEducation.company.scope'
                                error={ errors['workAndEducation.company.scope'] }
                                value={ fields.workAndEducation.company.scope }
                                onChange={ handlerChange }
                                suggestions={ companyScopeSuggestions }
                                loading={ isLoadingScope }
                                filtering={ false }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                type='number'
                                label='Количество сотрудников'
                                name='workAndEducation.company.employeesCount'
                                error={ errors['workAndEducation.company.employeesCount'] }
                                value={ fields.workAndEducation.company.employeesCount }
                                onChange={ handlerChange }
                                required
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 } className='p0'>
                        <Grid.Col>
                            <Input
                                type='text'
                                label='Наименование должности'
                                name='workAndEducation.employee.position'
                                error={ errors['workAndEducation.employee.position'] }
                                value={ fields.workAndEducation.employee.position }
                                onChange={ handlerChange }
                                required
                            />
                        </Grid.Col>
                    </Grid.Row>
                </>
            ) }
            { ![ 'Пенсионер', 'Нетрудоустроенный' ].includes(fields.workAndEducation.employmentType) && (
                <Grid.Row columns={ 3 } className='p0'>
                    <Grid.Col>
                        <Input
                            type='text'
                            label='Трудовой стаж на последнем месте работы с'
                            name='workAndEducation.employee.startDate'
                            error={ errors['workAndEducation.employee.startDate'] }
                            value={ fields.workAndEducation.employee.startDate }
                            onChange={ handlerChange }
                            mask='99.9999'
                            placeholder='мм.гггг'
                            required
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='number'
                            label='Общий трудовой стаж'
                            name='workAndEducation.employee.seniority'
                            error={ errors['workAndEducation.employee.seniority'] }
                            value={ fields.workAndEducation.employee.seniority }
                            onChange={ handlerChange }
                            postfix='лет'
                            required
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
            {fields.workAndEducation.employmentType === 'Нетрудоустроенный' && (
                <Grid.Row columns={ 1 } className='p0'>
                    <Grid.Col>
                        <Textarea
                            label='Комментарий'
                            name='workAndEducation.comment'
                            error={ errors['workAndEducation.comment'] }
                            value={ fields.workAndEducation.comment }
                            onChange={ handlerChange }
                        />
                    </Grid.Col>
                </Grid.Row>
            )}
            {fields.workAndEducation.employmentType !== 'Нетрудоустроенный' && (
                <Grid.Row columns={ 3 } className='p0'>
                    <Grid.Col>
                        <Dropdown
                            label='Наличие работы по совместительству'
                            name='workAndEducation.primeTimeJob'
                            error={ errors['workAndEducation.primeTimeJob'] }
                            value={ fields.workAndEducation.primeTimeJob }
                            options={ MYesNoOptions }
                            onChange={ handlerChange }
                            required
                        />
                    </Grid.Col>
                </Grid.Row>
            )}
            { fields.workAndEducation.primeTimeJob && (
                <>
                    <Grid.Row columns={ 1 } className='p0'>
                        <Grid.Col>
                            <Divider
                                content='Информация о работе по совместительству'
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <CompanyForm
                        name='workAndEducation.primeTimeJobCompany'
                        onChange={ handlerChange }
                        fields={ fields.workAndEducation.primeTimeJobCompany || {} }
                        errors={ {} }
                        required={ false }
                    />
                </>
            ) }
            <Grid.Row
                columns={ 1 }
                style={ {
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    background: ' #fff',
                } }
            >
                <Grid.Col>
                    <Button
                        className='mr-5'
                        content='Сохранить и продолжить'
                        onClick={ handlerSubmit(true) }
                        primary
                    />
                    <Button
                        className='mr-5'
                        content='Сохранить'
                        onClick={ handlerSubmit() }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ prevStep }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
};
