// @ts-ignore
import memoize from 'memoize';
import { Storage } from '@/utils';
import { axiosPublic } from './api-client/axiosPublic';

export const SESSION_STORAGE_KEY = 'session';

export const getJwtToken = (): string | null => Storage.getItem(SESSION_STORAGE_KEY);

export const saveJwtToken = (token: string) => {
    Storage.setItem(SESSION_STORAGE_KEY, token);
    return true;
};

export const removeJwtToken = () => {
    Storage.removeItem(SESSION_STORAGE_KEY);
    return true;
};

const refreshTokenFn = async (): Promise<string | null> => {
    try {
        const accessToken = Storage.getItem(SESSION_STORAGE_KEY);

        if (!accessToken) {
            Storage.removeItem(SESSION_STORAGE_KEY);
        }

        const response = await axiosPublic.get(`/auth/refresh-token/`, {
            headers: {
                Authorization: `Bearer ${ accessToken }`,
            },
        });

        const { token } = response.data;

        if (!token) {
            Storage.removeItem(SESSION_STORAGE_KEY);
        }

        Storage.setItem(SESSION_STORAGE_KEY, token);

        return token;
    } catch (error) {
        Storage.removeItem(SESSION_STORAGE_KEY);
    }
    return null;
};


export const memoizedRefreshToken: () => Promise<string | null> = memoize(refreshTokenFn, {
    maxAge: 1000,
});
