import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { UIIconButton } from 'finbox-ui-kit';

import { formatInitialsShort } from 'finbox-ui-kit/utils/format-initials';
import { FORMAT } from 'finbox-ui-kit/consts';
import { useCadastralNumberReports } from '@/common/cadastral-number/use-cadastral-reports';
import { EReportStatus, MReportStatus } from '@/common/cadastral-number/types';
import { useCadastralNumberReport } from '@/common/cadastral-number/use-cadastral-report';
import './cadastral-reports-list.scss';
import { Table } from '@/common/ui/table';
import { Block, Button, Loader, Modal, Textarea, useModal } from '@/common/ui';

type TCadastralReportsListProps = {
    cadastralNumber: string;
    clientId: number;
}
export const CadastralReportsList: React.FC<TCadastralReportsListProps> = ({ cadastralNumber, clientId }) => {
    const { show, hide, props } = useModal();
    const [ comment, setComment ] = useState('');
    const { download, preview } = useCadastralNumberReport();

    const {
        reports,
        total,
        isLoading,
        isFetched,
        isLoadingMakeReport,
        makeReport,
        refresh,
    } = useCadastralNumberReports({ cadastralNumber });

    const handlerClickViewReport = (reportId: number, cadastralNumber: string) => async () => {
        await preview(reportId, cadastralNumber);
    };

    const handlerClickDownloadReport = (reportId: number, cadastralNumber: string) => async () => {
        await download(reportId, cadastralNumber);
    };

    const handlerClickMakeReport = () => show();

    const handlerConfirmMakeReport = useCallback(async () => {
        hide();
        await makeReport(cadastralNumber, clientId, comment);
        await refresh();
    }, [ cadastralNumber, clientId, comment, hide, makeReport, refresh ]);

    const handlerCancelMakeReport = () => hide();

    const handlerChangeComment = useCallback((e, { value }) => {
        setComment(value);
    }, []);

    return (
        <Block
            className='cadastral-reports-list'
            title='Выписки ЕГРН'
            noPadding
        >
            { isLoading && (
                <div className='text-center color-grayDark relative pt1 pb1 m1'>
                    <Loader size='small' inverted/>
                </div>
            ) }
            { !isLoading && isFetched && !reports.length && (
                <div className='text-center color-grayDark m1'>
                    Нет данных
                </div>
            ) }
            { !isLoading && isFetched && reports.length > 0 && (
                <Table noBorder>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='pt_5 pb_5'>
                                Дата
                            </Table.HeaderCell>
                            <Table.HeaderCell className='pt_5 pb_5'>
                                Статус
                            </Table.HeaderCell>
                            <Table.HeaderCell className='pt_5 pb_5'>
                                Комментарий
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan={ 2 } className='pt_5 pb_5'>
                                Сотрудник
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { reports.map((report) => (
                            <Table.Row key={ report.id }>
                                <Table.Cell>
                                    { DateTime.fromISO(report.createdAt).toFormat(FORMAT.DATETIME) }
                                </Table.Cell>
                                <Table.Cell>
                                    { MReportStatus.get(report.status) }
                                </Table.Cell>
                                <Table.Cell>
                                    { report.comment }
                                </Table.Cell>
                                <Table.Cell>
                                    { formatInitialsShort(report.user) }
                                </Table.Cell>
                                <Table.Cell width={ 1 } className='nowrap'>
                                    { report.status === EReportStatus.PROCESSED && (
                                        <>
                                            <UIIconButton
                                                icon='magnifying-glass'
                                                iconType='light'
                                                onClick={ handlerClickViewReport(report.id, report.cadastralNumber) }
                                            />
                                            <UIIconButton
                                                icon='download'
                                                iconType='light'
                                                onClick={ handlerClickDownloadReport(report.id, report.cadastralNumber) }
                                            />
                                        </>
                                    ) }
                                </Table.Cell>
                            </Table.Row>
                        )) }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={ 5 } className='fz12 text-right pt_5 pb_5'>
                                Всего: { total }
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            ) }
            <div className='text-center m1'>
                <Button
                    type='button'
                    color='green'
                    content='Запросить выписку ЕГРН'
                    onClick={ handlerClickMakeReport }
                    loading={ isLoadingMakeReport }
                    disabled={ isLoading }
                />
            </div>
            <Modal
                { ...props }
                size='small'
            >
                <Modal.Header>Подтвердите запрос выписки ЕГРН</Modal.Header>
                <Modal.Content>
                    <Textarea
                        className='mb1'
                        name='comment'
                        label='Комментарий'
                        value={ comment }
                        onChange={ handlerChangeComment }
                    />
                    При завершении формирования выписки придёт уведомление.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Отправить'
                        onClick={ handlerConfirmMakeReport }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ handlerCancelMakeReport }
                    />
                </Modal.Actions>
            </Modal>
        </Block>
    );
};
