import React, { useRef } from 'react';
import { MBorrowerType } from '@/consts';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';
import { Button, Popup } from '@/common/ui';
import { useToggle } from '@/utils/hooks';


export const AddBorrowerButton = ({ onPick }) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { on, toggle } = useToggle();
    const handlerSelect: TSelectHandler = ({ value }) => {
        onPick(value);
        toggle(false);
    }
    const options: TOption[] = Array.from(MBorrowerType).map(([ value, text ]) => ({
        text,
        value,
    }));
    return (
        <Popup.Wrapper>
            <Button
                ref={ref}
                icon='plus'
                content='Добавить'
                onClick={() => toggle() }
            />
            <Popup targetRef={ref} open={on} onClickOutside={() => toggle(false)} style={{ zIndex: 9 }}>
                <OptionsList options={options} onSelect={handlerSelect}/>
            </Popup>
        </Popup.Wrapper>
    );
};