import { useCallback } from 'react';
import { TCoBorrowerForm } from '@/components/leads/components/client-edit-forms/types';
import { API_URLS } from '@/consts';
import { useApiClient } from '@/libs/api-client/use-api-client';

type useCoBorrowersProps = {
    clientId: number
}
type useCoBorrowersReturn = {
    updateCoBorrowers: (fields: TCoBorrowerForm[]) => Promise<void>
    loading: boolean
}
export function useCoBorrowers({ clientId }: useCoBorrowersProps): useCoBorrowersReturn {
    const { fetch, loading: isLoading } = useApiClient({
        method: 'put',
        url: `${API_URLS.CLIENTS.BASE}${clientId}/`,
    });

    const updateCoBorrowers = useCallback(async (borrowers) => {
        await fetch({
            borrowers,
        });
    }, [ fetch ]);
    return {
        updateCoBorrowers,
        loading: isLoading,
    }
}