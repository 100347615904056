import React from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { TSize } from '@/types/common';
import { Button } from '@/common/ui';

type CopyTextProps = {
    value: string;
    text?: string;
    size?: TSize;
    children?: React.ReactNode;
};
export function CopyText({ value, text, size, children }: CopyTextProps): React.ReactElement {
    return (
        <>
            { text || children || value }
            <Button
                type='button'
                className='ml-5'
                icon='copy'
                size={size}
                style={{ verticalAlign: 'middle' }}
                onClick={() => {
                    copy(value);
                    toast.info('Текст скопирован');
                }}
            />
        </>
    );
}