import React, { useCallback, useState } from 'react';
import scrollToElement from 'scroll-to-element';
import { UIButtonWithDropdown } from 'finbox-ui-kit';

import { makeInitialsError_LEGACY, validateStep } from '@/components/leads/components/client-edit-forms/validation';
import { TStepProps } from '@/components/leads/components/client-edit-forms/types';
import { formatManagerInitials, formatMoney } from '@/utils';
import {
    ECreditFormType,
    MCreditPurpose,
    MEstatePhase,
    MEstateType,
    MInitialPaymentInclude,
    MLoanSecurity,
    MYesNoOptions,
} from '@/components/leads/components/client-edit-forms/consts';
import { useDictionary } from '@/utils/hooks/use-dictionary';
import { Dropdown } from '@/common/ui/dropdown';
import { Grid } from '@/common/ui/grid';
import { Checkbox } from '@/common/ui/checkbox';
import { Input } from '@/common/ui/input';
import { Textarea } from '@/common/ui/textarea';
import { InputInitials } from '@/common/ui/input-initials';
import { TSelectHandler } from '@/common/options-list';
import { STATIC_PRODUCT } from '@/consts';
import { Button, Divider } from 'common/ui';


export const ClientStep: React.FC<TStepProps> = ({
    fields,
    formType,
    handlerChange,
    nextStep,
    prevStep,
    user,
    hideContinue,
}) => {
    const [ managers ] = useDictionary('managers', []);
    const [ products ] = useDictionary('products', []);
    const [ errors, setErrors ] = useState<any>({});

    const handlerLocalChange = useCallback((e, { name, value, checked }) => {
        handlerChange(e, {
            name,
            value: checked === undefined ? value : checked,
        });
        if (name === 'client.initials') {
            setErrors((current) => Object.keys(current).reduce((res, key) => {
                if (key.startsWith('client.initials')) {
                    return {
                        ...res,
                        [key]: null,
                    };
                }
                return res;
            }, current));
        } else {
            setErrors((c) => ({ ...c, [name]: null }));
        }
    }, [ handlerChange ]);

    const handlerSubmit = useCallback((next = false) => () => {
        const { isValid, errors } = validateStep('client', fields, formType, user);
        if (!isValid) {
            setErrors(errors);
            console.log(errors);
            setTimeout(() => {
                scrollToElement('.FUI-input.-error', { align: 'top', offset: -124 });
            }, 100);
            return;
        }
        nextStep(next);
    }, [ fields, formType, user, nextStep ]);

    const handlerChangeProduct = useCallback((value, { name }) => {
        let currentId = name.split('.').pop();
        currentId = currentId === 'null' ? null : Number(currentId);

        if (!value) {
            handlerChange(null, {
                name: 'client.products',
                value: fields.client.products.filter((i) => i !== currentId),
            });
        } else {
            handlerChange(null, {
                name: 'client.products',
                value: [
                    ...fields.client.products.filter((i) => i !== currentId),
                    value,
                ],
            });
        }
        setErrors((current) => Object.keys(current).reduce((res, key) => {
            if (key.startsWith('client.products')) {
                return {
                    ...res,
                    [key]: null,
                };
            }
            return res;
        }, current));
    }, [ fields.client.products, handlerChange ]);

    const handlerAddProduct: TSelectHandler = ({ value }) => {
        handlerChange(null, {
            name: 'client.products',
            value: [
                ...fields.client.products,
                value,
            ],
        });
        setErrors((c) => ({ ...c, 'client.products': null }));
    };

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                { fields.client.products.map((productId, index) => (
                    <Grid.Col key={ productId }>
                        <Dropdown
                            label='Продукт'
                            name={ `client.products.${ productId }` }
                            error={ errors[`client.products.${ index }`] }
                            value={ productId }
                            options={ products
                                .filter((p) => !fields.client.products.includes(p.id) || p.id === productId)
                                .map((product) => ({
                                    text: product.name,
                                    value: product.id,
                                })) }
                            onChange={ handlerChangeProduct }
                            clearable
                            required
                        />
                    </Grid.Col>
                )) }
                { (fields.client.products.length !== products.length) && (
                    <Grid.Col verticalAlign='middle'>
                        <UIButtonWithDropdown
                            content='Добавить продукт'
                            icon='plus'
                            // onClick={ handlerClickAddProduct }
                            color={ errors['client.products'] ? 'red' : null }
                            options={ products
                                .filter((p) => !fields.client.products.includes(p.id))
                                .map((product) => ({
                                    text: product.name,
                                    value: product.id,
                                })) }
                            onSelectOption={ handlerAddProduct }
                        />
                        { errors['client.products'] && (
                            <div className='color-red fz12 input -error'>
                                { errors['client.products'] }
                            </div>
                        ) }
                    </Grid.Col>
                ) }
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col fluid>
                    <Divider content='Основные данные'/>
                </Grid.Col>
                <Grid.Col fluid>
                    <InputInitials
                        name='client.initials'
                        label='ФИО'
                        value={ fields.client.initials }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                        error={ makeInitialsError_LEGACY('client.initials', errors) }
                        clearable
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='tel'
                        label='Телефон'
                        name='client.phone'
                        mask='+7 (999) 999-99-99'
                        value={ fields.client.phone }
                        error={ errors['client.phone'] }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='tel'
                        label='Whatsapp'
                        name='client.contactPhone'
                        mask='+7 (999) 999-99-99'
                        value={ fields.client.contactPhone }
                        error={ errors['client.contactPhone'] }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='text'
                        label='Email'
                        name='client.email'
                        value={ fields.client.email }
                        error={ errors['client.email'] }
                        onChange={ handlerLocalChange }
                        autoComplete='off'
                    />
                </Grid.Col>
                <Grid.Col fluid>
                    <Divider content='Данные кредита/займа'/>
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Сумма кредита'
                        name='client.sum'
                        error={ errors['client.sum'] }
                        value={ fields.client.sum }
                        onChange={ handlerLocalChange }
                        postfix='руб.'
                        renderValue={ (value) => formatMoney(value, false) }
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Срок кредита'
                        name='client.period'
                        error={ errors['client.period'] }
                        value={ fields.client.period }
                        onChange={ handlerLocalChange }
                        postfix='лет'
                        required
                    />
                </Grid.Col>

            </Grid.Row>
            {fields.client.products.includes(STATIC_PRODUCT.LEASING) && (
                <Grid.Row columns={ 3 }>
                    <Grid.Col fluid>
                        <Divider content='Данные для продукта "Возвратный лизинг"'/>
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='number'
                            label='Авансовый платеж'
                            name='credit.advancePayment'
                            error={ errors['credit.advancePayment'] }
                            value={ fields.credit.advancePayment }
                            onChange={ handlerLocalChange }
                            postfix='%'
                            required
                        />
                    </Grid.Col>
                </Grid.Row>
            )}
            {fields.client.products.includes(STATIC_PRODUCT.MORTGAGE) && (
                <Grid.Row columns={ 3 }>
                    <Grid.Col fluid>
                        <Divider content='Данные для продукта "Ипотека"'/>
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='number'
                            label='Сумма первоначального взноса'
                            name='credit.initialPaymentSum'
                            error={ errors['credit.initialPaymentSum'] }
                            value={ fields.credit.initialPaymentSum }
                            onChange={ handlerLocalChange }
                            postfix='руб.'
                            renderValue={ (value) => formatMoney(value, false) }
                            required
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Учитывать в первоначальном взносе'
                            name='credit.initialPaymentInclude'
                            error={ errors['credit.initialPaymentInclude'] }
                            value={ fields.credit.initialPaymentInclude }
                            options={ MInitialPaymentInclude.map((item) => ({
                                text: item,
                                value: item,
                            })) }
                            onChange={ handlerLocalChange }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            label='Кредитная программа'
                            name='credit.creditProgramName'
                            error={ errors['credit.creditProgramName'] }
                            value={ fields.credit.creditProgramName }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Использование субсидий'
                            name='credit.subsidies'
                            error={ errors['credit.subsidies'] }
                            value={ fields.credit.subsidies }
                            options={ MYesNoOptions }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                </Grid.Row>
            )}
            <Divider content='Дополнительно'/>
            <Grid.Row columns={ 3 }>
                { formType !== ECreditFormType.LOAN && (
                    <Grid.Col>
                        <Dropdown
                            label='Цель кредита'
                            name='credit.purpose'
                            error={ errors['credit.purpose'] }
                            value={ fields.credit.purpose }
                            options={ MCreditPurpose.map((item) => ({
                                text: item,
                                value: item,
                            })) }
                            onChange={ handlerLocalChange }
                        />
                    </Grid.Col>
                ) }
                { fields.credit.purpose === 'Приобретение недвижимости' && (
                    <>
                        <Grid.Col>
                            <Dropdown
                                label='Вид приобретаемой недвижимости'
                                name='credit.estateType'
                                error={ errors['credit.estateType'] }
                                value={ fields.credit.estateType }
                                options={ MEstateType.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                onChange={ handlerLocalChange }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Dropdown
                                label='Состояние объекта'
                                name='credit.estatePhase'
                                error={ errors['credit.estatePhase'] }
                                value={ fields.credit.estatePhase }
                                options={ MEstatePhase.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                onChange={ handlerLocalChange }
                                required
                            />
                        </Grid.Col>
                    </>
                ) }
                { formType !== ECreditFormType.LOAN && (
                    <Grid.Col>
                        <Dropdown
                            label='Обеспечение по кредиту'
                            name='credit.loanSecurity'
                            error={ errors['credit.loanSecurity'] }
                            value={ fields.credit.loanSecurity }
                            options={ MLoanSecurity.map((item) => ({
                                text: item,
                                value: item,
                            })) }
                            onChange={ handlerLocalChange }
                        />
                    </Grid.Col>
                ) }
                <Grid.Col>
                    <Dropdown
                        label='Страхование'
                        name='credit.insurance'
                        error={ errors['credit.insurance'] }
                        value={ fields.credit.insurance }
                        options={ MYesNoOptions }
                        onChange={ handlerLocalChange }
                    />
                </Grid.Col>
            </Grid.Row>

            { fields.credit.insurance && (
                <Grid.Row columns={ 3 } className='p0'>
                    <Grid.Col fluid>
                        <Grid stackable>
                            <Grid.Row columns={ 2 }>
                                <Grid.Col fluid>
                                    <h5 className='mb0'>Виды страхования</h5>
                                </Grid.Col>
                                <Grid.Col>
                                    <Checkbox
                                        label='Объекта залога от риска утраты и повреждения'
                                        name='credit.insuranceTypes.objectOfPledge'
                                        checked={ fields.credit.insuranceTypes.objectOfPledge }
                                        onChange={ handlerLocalChange }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Checkbox
                                        label='Жизни и трудоспособности Заемщика'
                                        name='credit.insuranceTypes.lifeAndWorkCapacity'
                                        checked={ fields.credit.insuranceTypes.lifeAndWorkCapacity }
                                        onChange={ handlerLocalChange }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Checkbox
                                        label='Титула имущества'
                                        name='credit.insuranceTypes.titleOfProperty'
                                        checked={ fields.credit.insuranceTypes.titleOfProperty }
                                        onChange={ handlerLocalChange }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Checkbox
                                        label='Коллективное'
                                        name='credit.insuranceTypes.collective'
                                        checked={ fields.credit.insuranceTypes.collective }
                                        onChange={ handlerLocalChange }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                    </Grid.Col>
                </Grid.Row>
            ) }
            <Grid.Row columns={ 3 }>
                <Grid.Col fluid>
                    <Textarea
                        label='Комментарий'
                        name='client.comment'
                        error={ errors['client.comment'] }
                        value={ fields.client.comment }
                        onChange={ handlerLocalChange }
                        rows={ 5 }
                    />
                </Grid.Col>
                { user.admin && (
                    <Grid.Col>
                        <Dropdown
                            label='Сотрудник'
                            name='client.manager'
                            error={ errors['client.manager'] }
                            value={ fields.client.manager }
                            options={ managers.map((manager) => ({
                                text: formatManagerInitials(manager),
                                value: manager.id,
                            })) }
                            onChange={ handlerLocalChange }
                            required
                        />
                    </Grid.Col>
                ) }
            </Grid.Row>
            <Grid.Row
                columns={ 1 }
                style={ {
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    background: ' #fff',
                } }
            >
                <Grid.Col>
                    { !hideContinue && (
                        <Button
                            className='mr-5'
                            content='Сохранить и продолжить'
                            onClick={ handlerSubmit(true) }
                            primary
                        />
                    ) }
                    <Button
                        className='mr-5'
                        content='Сохранить'
                        onClick={ handlerSubmit() }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ prevStep }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
};
