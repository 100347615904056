import React from 'react';
import i18next from 'i18next';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';
import { FUIModalsProvider } from 'finbox-ui-kit/context/modals.context';
import { CreditFormTranslation } from '@/components/leads/components/client-edit-forms/translation';
import { AuthContextProvider } from '@/context/auth.context';
import { SocketsProvider } from '@/libs/sockets/sockets.context';
import '@/assets/scss/index.scss';
import AppRoot from './app-root';
import ErrorBoundary from './error-boundary';

i18next.init({
    lng: 'ru',
    debug: false,
    resources: {
        ru: {
            translation: CreditFormTranslation,
        },
    },
}).then(() => {
    // console.info('i18n initialized');
});

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
            registration.addEventListener("updatefound", () => {
                // If updatefound is fired, it means that there's
                // a new service worker being installed.
                const installingWorker = registration.installing;
                console.log(
                    "A new service worker is being installed:",
                    installingWorker,
                );

                // You can listen for changes to the installing service worker's
                // state via installingWorker.onstatechange
            });
        })
        .catch((error) => {
            console.error(`Service worker registration failed: ${error}`);
        });
} else {
    console.error("Service workers are not supported.");
}

const router = createBrowserRouter([
    {
        path: "*",
        element: <AppRoot/>,
        errorElement: <ErrorBoundary/>,
    },
]);


function App() {
    return (
        <SocketsProvider>
            <AuthContextProvider>
                <FUIModalsProvider>
                    <RouterProvider
                        router={ router }
                    />
                </FUIModalsProvider>
            </AuthContextProvider>
        </SocketsProvider>
    );
}

export default App;
