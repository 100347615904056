import { EBorrowerType } from '@/consts';
import { initialsScheme } from '@/components/leads/components/client-edit-forms/schemas/initials.scheme';
import { passportScheme } from '@/components/leads/components/client-edit-forms/schemas/passport.scheme';
import { familyScheme } from '@/components/leads/components/client-edit-forms/schemas/family.scheme';
import { workAndEducationScheme } from '@/components/leads/components/client-edit-forms/schemas/work-and-education.scheme';
import { financesScheme } from '@/components/leads/components/client-edit-forms/schemas/finances.scheme';
import { Joi } from '@/libs/joi';

export const borrowerScheme = Joi.object({
    type: Joi.string().allow(...Object.values(EBorrowerType)).required(),
    initials: initialsScheme.required(),
    phone: Joi.phone().required(),
    oldInitials: Joi.object().when('initialsChanged', {
        is: true,
        then: initialsScheme.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    initialsChanged: Joi.boolean(),
    passport: passportScheme.required(),
    family: familyScheme.required(),
    workAndEducation: workAndEducationScheme.required(),
    finances: financesScheme.required(),
});
