import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import scrollToElement from 'scroll-to-element';
import { DateTime } from 'luxon';
import { FORMAT } from 'finbox-ui-kit/consts';
import { isDefined } from 'finbox-ui-kit/utils';
import { TStepProps } from '@/components/leads/components/client-edit-forms/types';
import { API_URLS } from '@/consts';
import { formatMoney, transformUrlParams } from '@/utils';
import { ImagesUploader } from '@/common/ui/images-uploader';
import { CadastralNumberRequestList } from '@/common/cadastral-number/cadastral-number-request-list';
import { validateStep } from '@/components/leads/components/client-edit-forms/validation';
import { MPledgeObjectDocumentType } from '@/components/leads/components/client-edit-forms/consts';
import { arrayToTree, treeToArray } from '@/utils/tree-utils';
import { useDictionary } from '@/utils/hooks/use-dictionary';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputAddress } from '@/common/ui/input-address';
import { Grid } from '@/common/ui/grid';
import { Input } from '@/common/ui/input';
import { Dropdown } from '@/common/ui/dropdown';
import { Textarea } from '@/common/ui/textarea';
import { TOption } from '@/common/ui/options-list';
import { ILeadPledgeObject } from '@/components/leads/pladge-object-types';
import { CopyText } from '@/common/copy-text';
import { Accordion } from '@/common/ui/accordion';
import { Block, Button, Divider, Icon, Message } from 'common/ui';


export const PledgeObjectStep: React.FC<TStepProps> = ({
    fields,
    formType,
    handlerChange,
    nextStep,
    prevStep,
}) => {
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ errors, setErrors ] = useState<Record<string, string | null>>({});
    const [ rosreestrObjectIndex, setRosreestrObjectIndex ] = useState<number>(null);
    const [ lastChanged, setLastChanged ] = useState<'cadastral' | 'address'>('cadastral');
    const [ cadastralList, setCadastralList ] = useState({
        count: 0,
        data: [],
    });
    const [ realtyTypes ] = useDictionary('realtyTypes', []);

    const { loading: isSearchLoading, fetch: fetchSearch } = useApiClient({
        method: 'post',
        url: API_URLS.EGRN.CADASTRAL_NUMBER,
    });

    const { loading: isCadastralDetailLoading, fetch: fetchCadastralDetail } = useApiClient({
        method: 'get',
        url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, {}),
    });

    const isLoading = isSearchLoading || isCadastralDetailLoading;

    const updateFromCadastralDetail = useCallback(async (cadastralNumber: string, objectIndex: number) => {
        try {
            const data = await fetchCadastralDetail(null, {
                url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
            });
            const [ info ] = data.elements;

            handlerChange(null, {
                name: `pledgeObjects.${ objectIndex }`,
                value: {
                    ...fields.pledgeObjects[objectIndex],
                    address: info.address,
                    area: parseFloat(info.area),
                    levelFloor: info.levelFloor ? parseInt(info.levelFloor) : null,
                    objType: info.objType,
                    cadNumber: info.cadNumber,
                    cadCost: parseInt(info.cadCost),
                    cadCostDeterminationDate:
                        info.cadCostDeterminationDate
                            ? DateTime.fromMillis(info.cadCostDeterminationDate).toFormat(FORMAT.DATE)
                            : null,
                    cadCostRegistrationDate:
                        info.cadCostRegistrationDate
                            ? DateTime.fromMillis(info.cadCostRegistrationDate).toFormat(FORMAT.DATE)
                            : null,
                    infoUpdateDate:
                        info.infoUpdateDate
                            ? DateTime.fromMillis(info.infoUpdateDate).toFormat(FORMAT.DATE)
                            : null,
                    purpose: info.purpose,
                    status: Number(info.status),
                    regDate: DateTime.fromMillis(info.regDate).toFormat(FORMAT.DATE),
                    rights: (info.rights || []).map((i) => ({
                        rightNumber: i.rightNumber,
                        rightTypeDesc: i.rightTypeDesc,
                        rightRegDate:
                            i.rightRegDate
                                ? DateTime.fromMillis(i.rightRegDate).toFormat(FORMAT.DATE)
                                : null,
                    })),
                    encumbrances: (info.encumbrances || []).map((i) => ({
                        encumbranceNumber: i.encumbranceNumber,
                        typeDesc: i.typeDesc,
                        startDate:
                            i.startDate
                                ? DateTime.fromMillis(i.startDate).toFormat(FORMAT.DATE)
                                : null,
                    })),
                    rosreestrDataUpdatedAt: DateTime.now(),
                },
            });
        } catch (e) {
            toast.error('Не удалось получить данные об объекте по кадастровому номеру');
            throw e;
        }
    }, [ fetchCadastralDetail, fields.pledgeObjects, handlerChange ]);

    const searchCadastralByAddress = useCallback(async (address, objectIndex) => {
        const response = await fetchSearch({
            address,
        });
        if (response.count === 0) {
            toast.error('Кадастровый номер не найден');
        } else if (response.count === 1) {
            const [ item ] = response.data;
            handlerChange(null, {
                name: `pledgeObjects.${ objectIndex }.cadNumber`,
                value: item.cadnum,
            });
            await updateFromCadastralDetail(item.cadnum, objectIndex);
        } else {
            setCadastralList(response);
        }
    }, [ fetchSearch, handlerChange, updateFromCadastralDetail ]);

    const handlerLocalChange = useCallback((index): any => (e, { name, value, checked }) => {
        handlerChange(e, {
            name: `pledgeObjects.${ index }.${ name }`,
            value: checked === undefined ? value : checked,
        });
        setErrors((c) => ({ ...c, [name]: null }));
        if (name === 'address' && value) {
            setLastChanged('address');
        } else if (name === 'cadNumber') {
            setLastChanged('cadastral');
        }
    }, [ handlerChange ]);

    const handlerClickAddRight = useCallback((index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ index }.rights`,
            value: [
                ...fields.pledgeObjects[index].rights,
                {
                    rightNumber: null,
                    rightTypeDesc: null,
                    rightRegDate: null,
                },
            ],
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerClickRemoveRight = useCallback((objectIndex, index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.rights`,
            value: fields.pledgeObjects[objectIndex].rights.filter((r, i) => i !== index),
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerChangeRightField = useCallback((objectIndex, index) => (e, { name, value }) => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.rights`,
            value: fields.pledgeObjects[objectIndex].rights.map((right, i) => i !== index ? right : { ...right, [name]: value }),
        });
        setErrors((current) => ({
            ...current,
            [`rights.${ index }.${ name }`]: null,
        }));
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerClickAddEncumbrance = useCallback((index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ index }.encumbrances`,
            value: [
                ...fields.pledgeObjects[index].encumbrances,
                {
                    encumbranceNumber: null,
                    typeDesc: null,
                    startDate: null,
                },
            ],
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerChangeEncumbranceField = useCallback((objectIndex, index) => (e, { name, value }) => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.encumbrances`,
            value: fields.pledgeObjects[objectIndex].encumbrances.map((encumbrance, i) => i !== index ? encumbrance : { ...encumbrance, [name]: value }),
        });
        setErrors((current) => ({
            ...current,
            [`encumbrances.${ index }.${ name }`]: null,
        }));
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerClickRemoveEncumbrance = useCallback((objectIndex, index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.encumbrances`,
            value: fields.pledgeObjects[objectIndex].encumbrances.filter((r, i) => i !== index),
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerSubmit = useCallback((next = false) => () => {
        const { isValid, errors } = validateStep('pledgeObjects', fields, formType);
        if (!isValid) {
            setErrors(errors);
            setTimeout(() => {
                scrollToElement('.FUI-input.-error', { align: 'top', offset: -124 });
            }, 100);
            return;
        }
        nextStep(next);
    }, [ nextStep, fields, formType ]);

    const handleClickRosreestr = useCallback((index) => async () => {
        setRosreestrObjectIndex(index);
        if (!fields.pledgeObjects[index].address && !fields.pledgeObjects[index].cadNumber) {
            toast.error('Для поиска требуется указать адрес или кадастровый номер');
            return;
        }
        if (lastChanged === 'cadastral' && fields.pledgeObjects[index].cadNumber) {
            await updateFromCadastralDetail(fields.pledgeObjects[index].cadNumber, index);
        } else {
            await searchCadastralByAddress(fields.pledgeObjects[index].address.data, index);
        }
    }, [ fields.pledgeObjects, lastChanged, updateFromCadastralDetail, searchCadastralByAddress ]);

    const handlerCloseCadastralList = useCallback(() => {
        setCadastralList(null);
    }, []);

    const handlerClickCadastralListItem = useCallback(async (cn: string) => {
        await updateFromCadastralDetail(cn, rosreestrObjectIndex);
        setCadastralList(null);
    }, [ updateFromCadastralDetail, rosreestrObjectIndex ]);

    const handlerClickAddDocument = useCallback((index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ index }.documents`,
            value: [
                ...fields.pledgeObjects[index].documents,
                {
                    type: null,
                    date: null,
                    number: null,
                },
            ],
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerChangeDocumentField = useCallback((objectIndex, index) => (e, { name, value }) => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.documents`,
            value: fields.pledgeObjects[objectIndex].documents.map((right, i) => i !== index ? right : { ...right, [name]: value }),
        });
        setErrors((current) => ({
            ...current,
            [`encumbrances.${ index }.${ name }`]: null,
        }));
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerClickRemoveDocument = useCallback((objectIndex, index) => () => {
        handlerChange(null, {
            name: `pledgeObjects.${ objectIndex }.documents`,
            value: fields.pledgeObjects[objectIndex].documents.filter((r, i) => i !== index),
        });
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerClickAddObject = React.useCallback(() => {
        handlerChange(null, {
            name: 'pledgeObjects',
            value: [
                ...fields.pledgeObjects,
                {
                    address: null,
                    area: null,
                    rights: [],
                    encumbrances: [],
                    documents: [],
                },
            ],
        });
        setTimeout(() => {
            scrollToElement(`#object-${ fields.pledgeObjects.length }`, {
                offset: -111,
                duration: 200,
            });
        }, 100);
    }, [ fields.pledgeObjects, handlerChange ]);

    const handlerCLickRemoveObject = (index: number) => () => {
        handlerChange(null, {
            name: 'pledgeObjects',
            value: fields.pledgeObjects.filter((o, i) => i !== index),
        });
    };

    const handlerUploadStarted = () => {
        setIsDisabled(true);
    };

    const handlerUploadFinished = () => {
        setIsDisabled(false);
    };

    const PledgeObjectWrapper = fields.pledgeObjects.length > 1 ? Block : 'div';

    const realtyTypesOptions = treeToArray(arrayToTree(realtyTypes || []));

    function getRealtyTypeText(type: number) {
        if (!isDefined(type)) {
            return null;
        }
        const item = realtyTypesOptions.find((i) => i.value === type);
        return item ? Array.from(item.chain).reverse().join(': ') : '';
    }

    return (
        <>
            <Message className='mb1'>
                <div style={ { display: 'flex', alignItems: 'flex-start', gap: '1em' } }>
                    <div style={ { fontSize: '3em', marginTop: '.75rem' } }>
                        <Icon name='circle-info' type='light' color='teal'/>
                    </div>
                    <div>
                        <Accordion
                            items={ [
                                {
                                    title: 'Работа с клиентом',
                                    content: (
                                        <ul className='m0 pl-5'>
                                            <li>Уточнить полный адрес (регион, район, населённый пункт, улица, дом, корпус, квартира/комната).</li>
                                            <li>Запросить кадастровый̆ номер (если есть возможность у клиента).</li>
                                        </ul>
                                    ),
                                },
                                {
                                    title: 'Если клиент предоставил некорректный адрес или не находит кадастровый номер:',
                                    content: (
                                        <ol className='m0 pl0'>
                                            <li>Сохранить в CRM адрес, который смогли получить от клиента.</li>
                                            <li>
                                                Попросить клиента заказать выписку ЕГРН по его объекту недвижимости в Госуслугах. <br/>
                                                <CopyText value='https://www.gosuslugi.ru/600359/1/form?_=1740472130825' size='tiny'>
                                                    <a href='https://www.gosuslugi.ru/600359/1/form?_=1740472130825' target='_blank'>https://www.gosuslugi.ru/600359/1/form?_=1740472130825</a>
                                                </CopyText>
                                            </li>
                                            <li>Перепроверить корректность адреса через &laquo;Гугл/Яндекс поиск&raquo; самостоятельно.</li>
                                        </ol>
                                    ),
                                },
                            ] }
                        />
                    </div>
                </div>
            </Message>

            <Grid stackable>
                { fields.pledgeObjects.map((pledgeObject: ILeadPledgeObject, index: number) => (
                    <PledgeObjectWrapper
                        key={ index }
                        id={ `object-${ index }` }
                        title={ fields.pledgeObjects.length > 1 ? `Объект ${ index + 1 }` : null }
                        buttons={ fields.pledgeObjects.length > 1 ? [ {
                            icon: 'trash',
                            onClick: handlerCLickRemoveObject(index),
                        } ] : null }
                    >
                        <Grid stackable>
                            { fields.pledgeObjects.length > 1 && (
                                <Grid.Row columns={ 1 }>
                                    <Grid.Col>
                                        <Divider data-object={ `object-${ index }` }>
                                            Объект { index + 1 }
                                            { (fields.pledgeObjects?.length > 1) && (
                                                <div className='ml1 cursor-pointer' onClick={ handlerCLickRemoveObject(index) }>
                                                    <Icon
                                                        name='trash'
                                                        color='red'
                                                    />
                                                </div>
                                            ) }
                                        </Divider>
                                    </Grid.Col>
                                </Grid.Row>
                            ) }
                            <Grid.Row columns={ 3 } className='pb0'>
                                <Grid.Col>
                                    <Dropdown
                                        id='type'
                                        name='type'
                                        label='Тип недвижимости'
                                        options={ treeToArray(arrayToTree(realtyTypes || [])).map((item) => ({
                                            text:
                                                (item.hasChildren)
                                                    ? (<b className='color-blue'>{ item.text }</b>)
                                                    : '--'.repeat(item.level) + ' ' + item.text,
                                            value: item.value,
                                            notSelectable: item.hasChildren,
                                        } as TOption)) }
                                        onChange={ handlerLocalChange(index) }
                                        error={ errors[`pledgeObjects.${ index }.type`] }
                                        value={ pledgeObject.type }
                                        text={ getRealtyTypeText(pledgeObject.type) }
                                        autoComplete='off'
                                        filtering
                                        filteringInclude
                                        required
                                        clearable
                                    />
                                </Grid.Col>
                                <Grid.Col colspan={ 2 }>
                                    <div style={ { display: 'flex', alignItems: 'center', gap: '1em' } }>
                                        <div style={ { flex: 1 } }>
                                            <InputAddress
                                                label='Адрес'
                                                name='address'
                                                error={ errors[`pledgeObjects.${ index }.address`] }
                                                value={ pledgeObject.address }
                                                onChange={ handlerLocalChange(index) }
                                                required
                                            />
                                        </div>
                                        <Button
                                            style={ { zIndex: 1 } }
                                            icon='magnifying-glass'
                                            onClick={ handleClickRosreestr(index) }
                                            disabled={ isLoading }
                                            loading={ isLoading }
                                        />
                                    </div>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row columns={ 3 } className='pb0'>
                                <Grid.Col>
                                    <Input
                                        type='text'
                                        label='Кадастровый номер'
                                        name='cadNumber'
                                        error={ errors.cadNumber }
                                        value={ pledgeObject.cadNumber }
                                        onChange={ handlerLocalChange(index) }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Dropdown
                                        label='Статус объекта'
                                        name='status'
                                        error={ errors.status }
                                        value={ pledgeObject.status }
                                        options={ [
                                            {
                                                text: 'Актуально',
                                                value: 1,
                                            },
                                            {
                                                text: 'Не актуально',
                                                value: 0,
                                            },
                                        ] }
                                        onChange={ handlerLocalChange(index) }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row columns={ 3 } className='pt0 pb0'>
                                <Grid.Col>
                                    <Input
                                        type='number'
                                        label='Площадь, кв.м'
                                        name='area'
                                        error={ errors.area }
                                        value={ pledgeObject.area }
                                        onChange={ handlerLocalChange(index) }
                                        postfix='кв.м'
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Input
                                        type='number'
                                        label='Этаж'
                                        name='levelFloor'
                                        error={ errors.levelFloor }
                                        value={ pledgeObject.levelFloor }
                                        onChange={ handlerLocalChange(index) }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Input
                                        type='number'
                                        label='Кадастровая стоимость'
                                        name='cadCost'
                                        error={ errors.cadCost }
                                        value={ pledgeObject.cadCost }
                                        onChange={ handlerLocalChange(index) }
                                        postfix='руб.'
                                        renderValue={ (value) => formatMoney(value, false) }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Input
                                        type='number'
                                        label='Примерная рыночная стоимость'
                                        name='marketPrice'
                                        error={ errors.marketPrice }
                                        value={ pledgeObject.marketPrice }
                                        onChange={ handlerLocalChange(index) }
                                        postfix='руб.'
                                        renderValue={ (value) => formatMoney(value, false) }
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Input
                                        type='text'
                                        inputMode='numeric'
                                        label='Дата обновления информации'
                                        name='infoUpdateDate'
                                        error={ errors.infoUpdateDate }
                                        value={ pledgeObject.infoUpdateDate }
                                        onChange={ handlerLocalChange(index) }
                                        mask='99.99.9999'
                                        placeholder='дд.мм.гггг'
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            { pledgeObject.documents.map((item, documentIndex) => (
                                <Grid.Row key={ `document-${ documentIndex }` } columns={ 15 } className='pt0 pb0'>
                                    <Grid.Col colspan={ 14 }>
                                        <Dropdown
                                            label='Правоустанавливающий документ'
                                            name='type'
                                            value={ item.type }
                                            error={ get(errors, `pledgeObjects.${ index }.documents.${ documentIndex }.type`) }
                                            options={ Array.from(MPledgeObjectDocumentType).map(([ code, name ]) => ({
                                                text: name,
                                                value: code,
                                            })) }
                                            onChange={ handlerChangeDocumentField(index, documentIndex) }
                                            required
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 1 }>
                                        <Button
                                            className='mt-6'
                                            icon='xmark'
                                            onClick={ handlerClickRemoveDocument(index, documentIndex) }
                                            color='white'
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            )) }
                            { (pledgeObject.documents || []).length < 3 && (
                                <Grid.Row className='pt0'>
                                    <Grid.Col fluid>
                                        <Button
                                            icon='plus'
                                            content='Добавить правоустанавливающий документ'
                                            onClick={ handlerClickAddDocument(index) }
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            ) }
                            { pledgeObject.rights.map((item, rightIndex) => (
                                <Grid.Row key={ `right-${ rightIndex }` } columns={ 15 } className='pt0 pb0'>
                                    <Grid.Col colspan={ 5 }>
                                        <Input
                                            type='text'
                                            label='Номер права'
                                            name='rightNumber'
                                            value={ item.rightNumber }
                                            error={ get(errors, `rights.${ rightIndex }.rightNumber`) }
                                            onChange={ handlerChangeRightField(index, rightIndex) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 5 }>
                                        <Input
                                            type='text'
                                            label='Описание'
                                            name='rightTypeDesc'
                                            error={ get(errors, `rights.${ rightIndex }.rightTypeDesc`) }
                                            value={ item.rightTypeDesc }
                                            onChange={ handlerChangeRightField(index, rightIndex) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 4 }>
                                        <Input
                                            type='text'
                                            label='Дата регистрации'
                                            name='rightRegDate'
                                            value={ item.rightRegDate }
                                            error={ get(errors, `rights.${ rightIndex }.rightRegDate`) }
                                            onChange={ handlerChangeRightField(index, rightIndex) }
                                            mask='99.99.9999'
                                            placeholder='дд.мм.гггг'
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 1 }>
                                        <Button
                                            className='mt-6'
                                            icon='xmark'
                                            onClick={ handlerClickRemoveRight(index, rightIndex) }
                                            color='white'
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            )) }
                            <Grid.Row className='pt0'>
                                <Grid.Col fluid>
                                    <Button
                                        icon='plus'
                                        content='Добавить право'
                                        onClick={ handlerClickAddRight(index) }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            { pledgeObject.encumbrances.map((item, encumbranceIndex) => (
                                <Grid.Row key={ encumbranceIndex } columns={ 15 } className='pt0 pb0'>
                                    <Grid.Col colspan={ 5 }>
                                        <Input
                                            type='text'
                                            label='Номер ограничения'
                                            name='encumbranceNumber'
                                            value={ item.encumbranceNumber }
                                            error={ get(errors, `encumbrances.${ encumbranceIndex }.encumbranceNumber`) }
                                            onChange={ handlerChangeEncumbranceField(index, encumbranceIndex) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 5 }>
                                        <Input
                                            type='text'
                                            label='Описание'
                                            name='typeDesc'
                                            value={ item.typeDesc }
                                            error={ get(errors, `encumbrances.${ encumbranceIndex }.typeDesc`) }
                                            onChange={ handlerChangeEncumbranceField(index, encumbranceIndex) }
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 4 }>
                                        <Input
                                            type='text'
                                            label='Дата регистрации'
                                            name='startDate'
                                            value={ item.startDate }
                                            error={ get(errors, `encumbrances.${ encumbranceIndex }.startDate`) }
                                            onChange={ handlerChangeEncumbranceField(index, encumbranceIndex) }
                                            mask='99.99.9999'
                                            placeholder='дд.мм.гггг'
                                        />
                                    </Grid.Col>
                                    <Grid.Col colspan={ 1 }>
                                        <Button
                                            className='mt-6'
                                            icon='xmark'
                                            onClick={ handlerClickRemoveEncumbrance(index, encumbranceIndex) }
                                            color='white'
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            )) }
                            <Grid.Row className='pt0'>
                                <Grid.Col fluid>
                                    <Button
                                        icon='plus'
                                        content='Добавить ограничение'
                                        onClick={ handlerClickAddEncumbrance(index) }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row className='pt0'>
                                <Grid.Col fluid>
                                    <Textarea
                                        label='Дополнительная информация для кредитора'
                                        name='comment'
                                        error={ errors.comment }
                                        value={ pledgeObject.comment }
                                        onChange={ handlerLocalChange(index) }
                                        rows={ 8 }
                                        placeholder={ (
                                            <>
                                                При необходимости прописать прописанных лиц. <br/>
                                                Использовался материнский капитал на приобретение. <br/>
                                                Использовался материнский капитал на погашение ипотеки. <br/>
                                                Приобретался объект в браке. <br/>
                                                Иная информация. <br/>
                                            </>
                                        ) }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            { pledgeObject.id && (
                                <>
                                    <Grid.Row>
                                        <Grid.Col fluid>
                                            <h4 className='mb0'>Документы объекта</h4>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row className='pt0' columns={ 1 }>
                                        <Grid.Col>
                                            <ImagesUploader
                                                itemURL={ transformUrlParams(API_URLS.LEADS.FILE, { id: fields.client.id }) }
                                                uploadURL={ transformUrlParams(API_URLS.LEADS.PLEDGE_OBJECT_UPLOAD_DOCUMENTS, {
                                                    id: fields.client.id,
                                                    pid: pledgeObject.id,
                                                }) }
                                                value={ pledgeObject.documentsImages || [] }
                                                onUploadStart={ handlerUploadStarted }
                                                onUploadFinished={ handlerUploadFinished }
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </>
                            ) }
                            { pledgeObject.id && (
                                <>
                                    <Grid.Row>
                                        <Grid.Col fluid>
                                            <h4 className='mb0'>Фото объекта</h4>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row className='pt0' columns={ 1 }>
                                        <Grid.Col>
                                            <ImagesUploader
                                                itemURL={ transformUrlParams(API_URLS.LEADS.FILE, { id: fields.client.id }) }
                                                uploadURL={ transformUrlParams(API_URLS.LEADS.PLEDGE_OBJECT_UPLOAD_IMAGES, {
                                                    id: fields.client.id,
                                                    pid: pledgeObject.id,
                                                }) }
                                                value={ pledgeObject.photos || [] }
                                                onUploadStart={ handlerUploadStarted }
                                                onUploadFinished={ handlerUploadFinished }
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </>
                            ) }
                        </Grid>
                    </PledgeObjectWrapper>
                )) }
                <Grid.Row className='pt0'>
                    <Grid.Col fluid>
                        <Button
                            icon='plus'
                            className='mt1'
                            content='Добавить объект залога'
                            onClick={ handlerClickAddObject }
                        />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row
                    columns={ 1 }
                    style={ {
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 20,
                        background: ' #fff',
                    } }
                >
                    <Grid.Col>
                        <Button
                            className='mr-5'
                            content='Сохранить и продолжить'
                            onClick={ handlerSubmit(true) }
                            disabled={ isDisabled }
                            primary
                        />
                        <Button
                            className='mr-5'
                            content='Сохранить'
                            onClick={ handlerSubmit() }
                            disabled={ isDisabled }
                            primary
                        />
                        <Button
                            content='Отмена'
                            onClick={ prevStep }
                            disabled={ isDisabled }
                        />
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <CadastralNumberRequestList
                loading={ isCadastralDetailLoading }
                address={ fields.pledgeObjects[rosreestrObjectIndex]?.address?.data }
                data={ cadastralList }
                onClose={ handlerCloseCadastralList }
                onClick={ handlerClickCadastralListItem }
                clientId={ fields.id }
            />
        </>
    );
};
