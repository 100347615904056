import { TUIColor } from 'finbox-ui-kit/types/common';
import { RosreestrCheckListStatus } from '@/types/rosreestr';
import { TIconBrandName, TIconName, TIconType } from '@/common/ui/icon/types';

export const VERSION = '2.40.0';

export const PRODUCTION = process.env.NODE_ENV === 'production';

export const WEB_URL = PRODUCTION ? 'https://ffo24.ru' : 'http://localhost:5173';

export const API_HOST = PRODUCTION ? 'https://ffo24.ru' : 'http://localhost:5173';

export const API_BASE_URL = `${ API_HOST }/api/v1`;
export const API_BASE_URL_V2 = `${ API_HOST }/api/v2`;

export const API_URLS = {
    CONFIG: `${ API_BASE_URL }/config/`,
    AUTH: {
        CURRENT_USER: `${ API_BASE_URL_V2 }/auth/check-auth/`,
    },
    CALLS: {
        LIST: `${ API_BASE_URL }/vpbx/`,
        ITEM: `${ API_BASE_URL }/vpbx/<id>/`,
    },
    CLIENTS: {
        BASE: `${ API_BASE_URL }/clients/`,
        ITEM: `${ API_BASE_URL }/clients/<id>/`,
        VALIDATE_FOR_DOCUMENT: `${ API_BASE_URL }/clients/<id>/validate-for-document`,
        COPY: `${ API_BASE_URL }/clients/<id>/copy/`,
    },
    USERS: {
        GETLIST: `${ API_BASE_URL }/users/`,
        GETALLLIST: `${ API_BASE_URL }/users/all/`,
        GETADMINSLIST: `${ API_BASE_URL }/users/admins/`,
        ACTIVE_AGENT: `${ API_BASE_URL }/users/active-agent/`,
        DISABLE_HISTORY: `${ API_BASE_URL }/users/disable_history/`,
        SHIFT_CLOSURE: `${ API_BASE_URL }/users/active-agent/shift-closure/`,
        CHANGE_ABSENCE: `${ API_BASE_URL }/users/<id>/change-absence/`,
        ITEM: `${ API_BASE_URL }/users/<id>/`,
        ITEM_EMAIL_CONNECT: `${ API_BASE_URL }/users/<id>/email-connect/`,
        GOV_ACCOUNT: `${ API_BASE_URL }/users/gov-account`,
        GOV_ACCOUNT_TWO_FACTOR: `${ API_BASE_URL }/users/gov-account-two-factor-auth/`,
        ASSIGNEE_LIST: `${ API_BASE_URL }/users/assignee-list/`,
    },
    GARBAGE: {
        BASE: API_BASE_URL + '/leads/deleted/',
        EXPORT: API_BASE_URL + '/garbage/export/',
        REPAIR: API_BASE_URL + '/leads/deleted/repair',
    },
    REPORTS: {
        STATUSES: `${ API_BASE_URL }/reports/statuses/`,
        ASSIGNEE_CREDITORS_CONTRACTS: `${ API_BASE_URL }/reports/assignee-creditors-contracts/`,
        DELETED_CLIENTS_REASONS: `${ API_BASE_URL }/reports/deleted-clients-reasons/`,
        SMS_CONVERSION: `${ API_BASE_URL }/reports/sms-conversion/`,
        ADV: `${ API_BASE_URL }/reports/adv/`,
        ADV_PRODUCTS: `${ API_BASE_URL }/reports/adv/products/`,
        ADV_CAMPAIGNS: `${ API_BASE_URL }/reports/adv/campaigns/`,
        ADV_CONTRACTS: `${ API_BASE_URL }/reports/adv/contracts/`,
        ADV_CLIENTS: `${ API_BASE_URL }/reports/adv/clients/`,
        TELEGRAM_BOT: `${ API_BASE_URL }/reports/telegram-bot/`,
        TELEGRAM_BOT_STATS: `${ API_BASE_URL }/reports/telegram-bot-stats/`,
        TELEGRAM_BOT_DOWNLOAD: `${ API_BASE_URL }/reports/telegram-bot/download`,
    },
    DICTIONARIES: {
        products: `${ API_BASE_URL }/dictionaries/products/`,
        creditors: `${ API_BASE_URL }/dictionaries/creditors/`,
        deletedReasons: `${ API_BASE_URL }/leads/deleted/deleted-reasons/`,
        statuses: `${ API_BASE_URL }/dictionaries/statuses/`,
        creditorsOrdersStatuses: `${ API_BASE_URL }/dictionaries/creditors-orders-statuses/`,
        managers: `${ API_BASE_URL }/dictionaries/managers/`,
        users: `${ API_BASE_URL }/dictionaries/managers/`,
        usersAll: `${ API_BASE_URL }/dictionaries/users/all/`,
        smsTemplates: `${ API_BASE_URL }/dictionaries/sms-templates/`,
        smsTemplatesSystem: `${ API_BASE_URL }/dictionaries/sms-templates-system/`,
        regions: `${ API_BASE_URL }/dictionaries/regions/`,
        transitOrderStatuses: `${ API_BASE_URL }/dictionaries/transit-order-statuses/`,
        DADATA: {
            ADDRESS: `${ API_BASE_URL }/dictionaries/dadata/address`,
            ADDRESS_CLEAN: `${ API_BASE_URL }/dictionaries/dadata/address-clean`,
            PASSPORT: `${ API_BASE_URL }/dictionaries/dadata/passport`,
            ORGANIZATION: `${ API_BASE_URL }/dictionaries/dadata/organization`,
            ORGANIZATION_BY_INN: `${ API_BASE_URL }/dictionaries/dadata/organization/by-inn/`,
            INITIALS: `${ API_BASE_URL }/dictionaries/dadata/initials`,
            INITIALS_CLEAN: `${ API_BASE_URL }/dictionaries/dadata/initials/clean`,
            OKVED2: `${ API_BASE_URL }/dictionaries/dadata/okved2`,
            BANK: `${ API_BASE_URL }/dictionaries/dadata/bank`,
        },
        CARS: `${ API_BASE_URL }/dictionaries/cars`,
        ORGANIZATION_CONTACTS: `${ API_BASE_URL }/dictionaries/organization_contacts`,
        realtyTypes: `${ API_BASE_URL }/dictionaries/realty_types`,
        documents: `${ API_BASE_URL }/dictionaries/documents`,
        'documents-groups': `${ API_BASE_URL }/dictionaries/documents-groups`,
        deleteClientReasons: `${ API_BASE_URL }/dictionaries/delete-client-reasons`,
        potentialCreditorStatuses: `${ API_BASE_URL }/dictionaries/potential-creditor-statuses`,
        eSignCompanies: `${ API_BASE_URL }/dictionaries/e-sign-companies`,
        advCampaigns: `${ API_BASE_URL }/dictionaries/adv-campaigns`,
        FIND_CREDITORS: `${ API_BASE_URL }/dictionaries/find-creditors`,
    },
    CREDITORS: {
        BASE: `${ API_BASE_URL }/creditors/`,
        CREATE_SHORT: `${ API_BASE_URL }/creditors/short/`,
        ITEM: '/creditors/<id>/',
        ORDERS: `${ API_BASE_URL }/creditors/orders/`,
        RATING: `${ API_BASE_URL }/creditors/rating/`,
        STATS: `${ API_BASE_URL }/creditors/stats/`,
        DOWNLOAD_CSV: `${ API_BASE_URL }/creditors/download-csv/`,
        PARTNERS: `${ API_BASE_URL }/creditors/partners/`,
        TRANSIT_CONDITIONS: `${ API_BASE_URL }/creditors/transit-conditions/`,
    },
    LEADS: {
        BASE: `${ API_BASE_URL }/leads/`,
        LIST_V2: `${ API_BASE_URL }/leads/v2/`,
        TASKS: `${ API_BASE_URL }/leads/tasks/`,
        COUNTERS: `${ API_BASE_URL }/leads/counters/`,
        SUGGEST: `${ API_BASE_URL }/leads/suggest/`,
        MULTI_EDIT: `${ API_BASE_URL }/leads/multi-edit/`,
        BORROWERS: `${ API_BASE_URL }/leads/borrowers/`,
        ITEM: `${ API_BASE_URL }/leads/<id>/`,
        REOPEN_LEAD: `${ API_BASE_URL }/leads/<id>/reopen/`,
        DK_OFFER: `${ API_BASE_URL }/leads/<id>/dk-offer/`,
        HISTORY: `${ API_BASE_URL }/leads/<id>/history/`,
        REMINDS: `${ API_BASE_URL }/leads/<id>/reminds/`,
        UPDATE_STATUS: `${ API_BASE_URL }/leads/<id>/update-status/`,
        CREDITOR_FROM: `${ API_BASE_URL }/leads/<id>/creditor-form/`,
        CREDITOR_FROM_DOWNLOAD: `${ API_BASE_URL }/leads/<id>/creditor-form/download`,
        PLEDGE_OBJECT: `${ API_BASE_URL }/leads/<id>/pledge-object`,
        PLEDGE_OBJECT_UPLOAD_IMAGES: `${ API_BASE_URL }/leads/<id>/pledge-object/<pid>/images`,
        PLEDGE_OBJECT_UPLOAD_DOCUMENTS: `${ API_BASE_URL }/leads/<id>/pledge-object/<pid>/documents`,
        PLEDGE_OBJECT_DOWNLOAD: `${ API_BASE_URL }/leads/<id>/pledge-object/download`,
        PLEDGE_OBJECT_DOWNLOAD_DOCUMENTS: `${ API_BASE_URL }/leads/<id>/pledge-object/download-documents`,
        FILES: `${ API_BASE_URL }/leads/<id>/file/pledgeObject/<pid>`,
        FILE: `${ API_BASE_URL }/leads/<id>/file/<fileName>`,
    },
    EGRN: {
        CADASTRAL_NUMBER: `${ API_BASE_URL }/egrn/cadastral-number/`,
        CADASTRAL_NUMBER_DETAIL: `${ API_BASE_URL }/egrn/cadastral-number/<cadastralNumber>/`,
    },
    CONTRACTS: {
        BASE: `${ API_BASE_URL }/deals/`,
        ITEM: `${ API_BASE_URL }/deals/<id>/`,
        CONFIRM: `${ API_BASE_URL }/deals/confirm/`,
        CONFIRM_CONTRACT: `${ API_BASE_URL }/deals/confirm/:id/`,
        CONFIRM_REWARD_RECEIVED: `${ API_BASE_URL }/deals/reward-received/:id/`,
        AGENCY_REWARD: `${ API_BASE_URL }/deals/agency_rewards/`,
        AGENCY_REWARD_CONFIRM: `${ API_BASE_URL }/deals/agency_rewards/:id/`,
    },
    SMS: {
        BASE: `${ API_BASE_URL }/sms/`,
        SEND: `${ API_BASE_URL }/sms/send/`,
        TEMPLATES: API_BASE_URL + '/sms/templates/',
        EVENTS: API_BASE_URL + '/sms/events/',
    },
    NOTIFICATIONS: `${ API_BASE_URL }/notifications/`,
    EMAIL: {
        SEND: `${ API_BASE_URL }/email/send/`,
        SUGGESTIONS: `${ API_BASE_URL }/email/suggestions/`,
        FOLDERS: `${ API_BASE_URL }/email/folders/`,
    },
    CONTRACT_TEMPLATES: {
        BASE: `${ API_BASE_URL }/contract-templates/`,
        ORDERS_FOR_CONTRACT: `${ API_BASE_URL }/contract-templates/orders-for-contract/`,
    },
    SEARCH: `${ API_BASE_URL }/search/`,
    ROSREESTR: {
        ACCOUNTS: `${ API_BASE_URL }/rosreestr/accounts/`,
        CHECK_LISTS: `${ API_BASE_URL }/rosreestr/check-lists/`,
    },
};

export const API_ERRORS = {
    auth: {
        default: 'Ошибка авторизации',
        40380: 'Превышено максимальное количество попыток',
        40301: 'Некорректный код подтверждения',
        40401: 'Некорректно введён логин или пароль',
    },
    users: {
        40401: 'Сотрудник не найден',
    },
    validator: {
        40001: (res) => `Некорректные данные: ${ res.message }`,
    },
    email: {
        50001: (err) => {
            switch (true) {
                case err.message.includes('Authentication failed'):
                    return 'Некорректные данные почтового ящика или запрещён доступ для почтовых программ';
                case err.message?.includes('SPAM'):
                    // eslint-disable-next-line max-len
                    return 'К сожалению Ваш почтовый сервис отметил отправляемые письма с вашего ящика как спам. Не отправляёте ближайшие 24 часа Email из системы. Каждая попытка продлит блокировку на 24 часа!';
                default:
                    return 'Внутренняя ошибка сервера';
            }
        },
    },
};


export enum CHANNEL {
    SMS = 'SMS',
    WHATSAPP = 'WHATSAPP',
    VIBER = 'VIBER',
    TELEGRAM = 'TELEGRAM',
}


export enum CHANNEL_ICON {
    SMS = 'envelope',
    WHATSAPP = 'whatsapp',
    VIBER = 'viber',
    TELEGRAM = 'telegram',
    CALL = 'phone',
}


export enum CHANNEL_ICON_COLOR {
    WHATSAPP = 'green',
    TELEGRAM = 'blue',
    SMS = 'teal',
    VIBER = 'violet',
    CALL = 'olive',
}


export enum ECreditorCapture {
    FULL = 'full',
    WITHOUT_SMS = 'without_sms',
    NO_CONTACT = 'no_contact',
}

export const MCreditorCapture = new Map<ECreditorCapture, string>([
    [ ECreditorCapture.FULL, 'Кредитор закрепляет заявку' ],
    [ ECreditorCapture.WITHOUT_SMS, 'Кредитор не уведомляет клиента о решении' ],
    [ ECreditorCapture.NO_CONTACT, 'Кредитору не предоставляются контакты клиента' ],
])

export enum ECreditorAdditionalFeatures {
    MINORS_REGISTERED = 'minors_registered',
    MINUS_COMMISSION = 'minus_commission',
    URGENT_PURCHASE = 'urgent_purchase',
    BANKRUPT = 'bankrupt',
}

export const CREDITOR_ADDITIONAL_FEATURES = new Map<ECreditorAdditionalFeatures, string>([
    [ ECreditorAdditionalFeatures.MINORS_REGISTERED, 'Прописаны несовершеннолетние' ],
    [ ECreditorAdditionalFeatures.MINUS_COMMISSION, 'Возможность минусовать комиссию' ],
    [ ECreditorAdditionalFeatures.URGENT_PURCHASE, 'Работа по срочному выкупу' ],
    [ ECreditorAdditionalFeatures.BANKRUPT, 'Рассмотрение банкротов' ],
]);

export enum ECreditorBorrowerType {
    EMPLOYEE = 'employee',
    SELF_EMPLOYED = 'self_employed',
    INDIVIDUAL_ENTREPRENEUR = 'individual_entrepreneur',
    UNOFFICIAL_BUSINESS = 'unofficial_business',
    OWNER_OF_BUSINESS = 'owner_of_business',
    LEGAL = 'legal',
    PENSIONER = 'pensioner',
    UNEMPLOYED = 'unemployed',
}

export const CREDITOR_BORROWER_TYPE = new Map<ECreditorBorrowerType, string>([
    [ ECreditorBorrowerType.EMPLOYEE, 'Наёмный работник' ],
    [ ECreditorBorrowerType.SELF_EMPLOYED, 'Самозанятый' ],
    [ ECreditorBorrowerType.INDIVIDUAL_ENTREPRENEUR, 'Индивидуальный предприниматель' ],
    [ ECreditorBorrowerType.UNOFFICIAL_BUSINESS, 'Неофициальный бизнес' ],
    [ ECreditorBorrowerType.OWNER_OF_BUSINESS, 'Владелец/Совладелец организации' ],
    [ ECreditorBorrowerType.LEGAL, 'Юридическое лицо (ООО, ЗАО и т.п.)' ],
    [ ECreditorBorrowerType.PENSIONER, 'Пенсионер' ],
    [ ECreditorBorrowerType.UNEMPLOYED, 'Нетрудоустроенный' ],
]);

export enum ECreditorProofType {
    WITHOUT_PROOF = 'without_proof',
    INDIVIDUAL_ACCOUNT_STATEMENT = 'individual_account_statement',
    EXTRACT_FROM_PVF = 'extract_from_pvf',
    WITHOUT_PROOF_WITH_COPY_OF_TC = 'without_proof_with_copy_of_tc',
    NOTE_FORM_CREDITOR_WITHOUT_TC = 'note_form_creditor_without_tc',
    NOTE_FORM_CREDITOR_WITH_TC = 'note_form_creditor_with_tc',
    NOTE_2NDFL_WITHOUT_TC = 'note_2ndfl_without_tc',
    NOTE_2NDFL_WITH_TC = 'note_2ndfl_with_tc',
    LEGAL_ENTITY_ACCOUNT_STATEMENT = 'legal_entity_account_statement',
    DECLARATION_OF_INCOME = 'declaration_of_income',
    BALANCE_FORM_1_2 = 'balance_form_1_2',
    OSV_50_51_60_62_76 = 'osv_50_51_60_62_76',
}

export const CREDITOR_PROOF_TYPE = new Map<ECreditorProofType, string>([
    [ ECreditorProofType.WITHOUT_PROOF, 'Без подтверждения дохода, без ТК' ],
    [ ECreditorProofType.INDIVIDUAL_ACCOUNT_STATEMENT, 'Выписка по счету физического лица' ],
    [ ECreditorProofType.EXTRACT_FROM_PVF, 'Выписка из ПВФ' ],
    [ ECreditorProofType.WITHOUT_PROOF_WITH_COPY_OF_TC, 'Без подтверждения дохода с копией ТК' ],
    [ ECreditorProofType.NOTE_FORM_CREDITOR_WITHOUT_TC, 'Справка по форме кредитора без ТК' ],
    [ ECreditorProofType.NOTE_FORM_CREDITOR_WITH_TC, 'Справка по форме кредитора с ТК' ],
    [ ECreditorProofType.NOTE_2NDFL_WITHOUT_TC, 'Справка 2 НДФЛ без ТК' ],
    [ ECreditorProofType.NOTE_2NDFL_WITH_TC, 'Справка 2 НДФЛ с ТК' ],
    [ ECreditorProofType.LEGAL_ENTITY_ACCOUNT_STATEMENT, 'Выписка по счету юридического лица' ],
    [ ECreditorProofType.DECLARATION_OF_INCOME, 'Декларация о доходах' ],
    [ ECreditorProofType.BALANCE_FORM_1_2, 'Баланс ф.1 .ф.2' ],
    [ ECreditorProofType.OSV_50_51_60_62_76, 'ОСВ 50,51, 60,62,76' ],
]);

export enum ECreditorMoney {
    ALL_COST_ON_SIGN = 'all_cost_on_sign',
    ALL_COST_AFTER_REGISTRATION = 'all_cost_after_registration',
    PARTIAL_COST_ON_SIGN = 'partial_cost_on_sign',
    PRESENCE_OF_TRENCHES = 'presence_of_trenches',
}

export const CREDITOR_MONEY = new Map<ECreditorMoney, string>([
    [ ECreditorMoney.ALL_COST_ON_SIGN, 'Вся сумма на момент подписания' ],
    [ ECreditorMoney.ALL_COST_AFTER_REGISTRATION, 'Вся сумма после регистрации' ],
    [ ECreditorMoney.PARTIAL_COST_ON_SIGN, 'Часть суммы на момент подписания' ],
    [ ECreditorMoney.PRESENCE_OF_TRENCHES, 'Возможен транш' ],
]);


export enum ECreditorContactType {
    PHONE = 'phone',
    WHATSAPP = 'whatsapp',
    EMAIL = 'email',
    SITE = 'site',
    TELEGRAM = 'telegram',
    VIBER = 'viber',
}


export enum ECreditorContactTarget {
    ORDER = 'order',
    PRIMARY = 'primary',
    SUPERVISOR = 'supervisor',
    OTHER = 'other',
}


export const CreditorContactTargetMap = new Map<ECreditorContactTarget, string>([
    [ ECreditorContactTarget.ORDER, 'Отправка заявки' ],
    [ ECreditorContactTarget.PRIMARY, 'Основной для связи' ],
    [ ECreditorContactTarget.SUPERVISOR, 'Руководитель' ],
    [ ECreditorContactTarget.OTHER, 'Прочее' ],
]);

export const CreditorContactType = new Map<ECreditorContactType, string>([
    [ ECreditorContactType.PHONE, 'Телефон' ],
    [ ECreditorContactType.WHATSAPP, 'Whatsapp' ],
    [ ECreditorContactType.TELEGRAM, 'Telegram' ],
    [ ECreditorContactType.EMAIL, 'Email' ],
    [ ECreditorContactType.SITE, 'Сайт' ],
    [ ECreditorContactType.VIBER, 'Viber' ],
]);

export const CreditorContactTypeIcon = new Map<ECreditorContactType, [ TIconName | TIconBrandName, TIconType ]>([
    [ ECreditorContactType.PHONE, [ 'phone', 'regular' ] ],
    [ ECreditorContactType.WHATSAPP, [ 'whatsapp', 'brands' ] ],
    [ ECreditorContactType.TELEGRAM, [ 'telegram', 'brands' ] ],
    [ ECreditorContactType.VIBER, [ 'viber', 'brands' ] ],
    [ ECreditorContactType.EMAIL, [ 'envelope', 'regular' ] ],
    [ ECreditorContactType.SITE, [ 'globe', 'regular' ] ],
]);

export const CreditorContactTypeIconColor = new Map<ECreditorContactType, TUIColor>([
    [ ECreditorContactType.PHONE, 'blue' ],
    [ ECreditorContactType.WHATSAPP, 'green' ],
    [ ECreditorContactType.TELEGRAM, 'teal' ],
    [ ECreditorContactType.EMAIL, 'orange' ],
    [ ECreditorContactType.SITE, 'violet' ],
    [ ECreditorContactType.VIBER, 'violet' ],
]);


export enum BANK_CAPTURE_COLOR {
    full = 'green',
    without_sms = 'yellow',
    no_contact = 'blue',
}


export enum COMMISSION_TYPE {
    PERCENT,
    COST
}


export const CITIES_DECL = [ 'город', 'города', 'городов' ];
export const DAYS_DECL = [ 'день', 'дня', 'дней' ];


export enum ECreditorOrderType {
    ORDER = 'order',
    ORDER_WITH_CONTACTS = 'order_with_contacts',
    FORM = 'form',
    FORM_WITH_CONTACTS = 'form_with_contacts',
    CREDITOR_FORM = 'creditor_form',
}


export const MCreditorOrderType = new Map<ECreditorOrderType, string>([
    [ ECreditorOrderType.ORDER, 'Заявка без контактов' ],
    [ ECreditorOrderType.ORDER_WITH_CONTACTS, 'Заявка с контактами' ],
    [ ECreditorOrderType.FORM, 'Анкета без контактов' ],
    [ ECreditorOrderType.FORM_WITH_CONTACTS, 'Анкета с контактами' ],
    [ ECreditorOrderType.CREDITOR_FORM, 'Форма кредитора' ],
    [ null, 'Не заполнено' ],
]);

export const MCreditorOrderTypeShort = new Map<ECreditorOrderType, string>([
    [ ECreditorOrderType.ORDER, 'Заявка' ],
    [ ECreditorOrderType.ORDER_WITH_CONTACTS, 'Заявка' ],
    [ ECreditorOrderType.FORM, 'Анкета' ],
    [ ECreditorOrderType.FORM_WITH_CONTACTS, 'Анкета' ],
    [ ECreditorOrderType.CREDITOR_FORM, 'Форма кредитора' ],
    [ null, 'Не заполнено' ],
]);

export const MCreditorOrderTypeColor = new Map<ECreditorOrderType, TUIColor>([
    [ ECreditorOrderType.ORDER, 'red' ],
    [ ECreditorOrderType.ORDER_WITH_CONTACTS, 'blue' ],
    [ ECreditorOrderType.FORM, 'red' ],
    [ ECreditorOrderType.FORM_WITH_CONTACTS, 'green' ],
    [ null, 'black' ],
]);


export enum EBorrowerType {
    PLEDGER = 'pledger',
    COBORROWER = 'coborrower',
    GUARANTOR = 'guarantor',
}


export const MBorrowerType = new Map([
    [ EBorrowerType.PLEDGER, 'Залогодатель' ],
    [ EBorrowerType.COBORROWER, 'Созаемщик' ],
    [ EBorrowerType.GUARANTOR, 'Поручитель' ],
]);


export enum EActiveType {
    CAR = 'car',
    REALTY = 'realty',
    BANK_DEPOSIT = 'bankDeposit',
}


export const MActiveType = new Map([
    [ EActiveType.CAR, 'Автомобиль' ],
    [ EActiveType.REALTY, 'Недвижимость' ],
    [ EActiveType.BANK_DEPOSIT, 'Вклад' ],
]);


export enum ECurrency {
    RUB = 'rub',
    DOLLAR = 'dollar',
    EURO = 'euro',
}


export const MCurrencyName = new Map([
    [ ECurrency.RUB, 'Российский рубль' ],
    [ ECurrency.DOLLAR, 'Доллар США' ],
    [ ECurrency.EURO, 'Евро' ],
]);

export const MCurrencyPrefix = new Map([
    [ ECurrency.RUB, 'руб.' ],
    [ ECurrency.DOLLAR, '$' ],
    [ ECurrency.EURO, '€' ],
]);


export enum ECreditorOrganizationType {
    BANK = 'bank',
    MFO = 'mfo',
    KPK = 'kpk',
    SKPK = 'skpk',
    MKK = 'mkk',
    PAWN = 'pawn',
    LK = 'lk',
    LEGAL = 'legal',
    AO = 'ao',
    OAO = 'oao',
    UK = 'uk',
    INDIVIDUAL = 'individual',
    SELF_EMPLOYED = 'self_employed',
    PRIVATE_LENDER = 'private_lender',
    INVESTMENT_FUND = 'investment_fund',
    MFK = 'mfk',
    OIP = 'oip',
    OTHER = 'other',
}


export const MCreditorOrganizationTypeShortcut = new Map([
    [ ECreditorOrganizationType.BANK, 'Банк' ],
    [ ECreditorOrganizationType.KPK, 'КПК' ],
    [ ECreditorOrganizationType.SKPK, 'СКПК' ],
    [ ECreditorOrganizationType.MKK, 'МКК' ],
    [ ECreditorOrganizationType.MFK, 'МФК' ],
    [ ECreditorOrganizationType.MFO, 'МФО' ],
    [ ECreditorOrganizationType.OIP, 'ОИП' ],
    [ ECreditorOrganizationType.PAWN, 'Ломбард' ],
    [ ECreditorOrganizationType.LK, 'ЛК' ],
    [ ECreditorOrganizationType.LEGAL, 'ООО' ],
    [ ECreditorOrganizationType.AO, 'АО' ],
    [ ECreditorOrganizationType.OAO, 'ОАО' ],
    [ ECreditorOrganizationType.UK, 'УК' ],
    [ ECreditorOrganizationType.INVESTMENT_FUND, 'ИФ' ],
    [ ECreditorOrganizationType.INDIVIDUAL, 'ИП' ],
    [ ECreditorOrganizationType.SELF_EMPLOYED, 'Самозанятый' ],
    [ ECreditorOrganizationType.PRIVATE_LENDER, 'ЧК' ],
    [ ECreditorOrganizationType.OTHER, 'Иное' ],
]);

export const MCreditorOrganizationTypeName = new Map<ECreditorOrganizationType, string>([
    [ ECreditorOrganizationType.BANK, 'Банк' ],
    [ ECreditorOrganizationType.MFO, 'Микрофинансовая организация' ],
    [ ECreditorOrganizationType.KPK, 'Кредитно потребительский кооператив' ],
    [ ECreditorOrganizationType.SKPK, 'Сельскохозяйственный кредитно потребительский кооператив' ],
    [ ECreditorOrganizationType.MKK, 'Микрокредитная компания' ],
    [ ECreditorOrganizationType.PAWN, 'Ломбард' ],
    [ ECreditorOrganizationType.LK, 'Лизинговая компания' ],
    [ ECreditorOrganizationType.LEGAL, 'Общество с ограниченной ответственностью' ],
    [ ECreditorOrganizationType.AO, 'Акционерное общество' ],
    [ ECreditorOrganizationType.OAO, 'Открытое акционерное общество' ],
    [ ECreditorOrganizationType.UK, 'Управляющая Компания' ],
    [ ECreditorOrganizationType.INDIVIDUAL, 'Индивидуальный предприниматель' ],
    [ ECreditorOrganizationType.SELF_EMPLOYED, 'Самозанятый' ],
    [ ECreditorOrganizationType.PRIVATE_LENDER, 'Частный кредитор' ],
    [ ECreditorOrganizationType.INVESTMENT_FUND, 'Инвестиционный фонд' ],
    [ ECreditorOrganizationType.MFK, 'Микрофинансовая компания' ],
    [ ECreditorOrganizationType.OIP, 'Оператор инвестиционных платформ' ],
    [ ECreditorOrganizationType.OTHER, 'Иное' ],
]);


export enum EClientAddressBasis {
    OWNER = 'owner',
    RENT = 'rent',
    RELATIVES = 'relatives',
}


export const MClientAddressBasis = new Map([
    [ EClientAddressBasis.OWNER, 'Собственность' ],
    [ EClientAddressBasis.RENT, 'Аренда' ],
    [ EClientAddressBasis.RELATIVES, 'Родственники' ],
]);


export enum E_TASK_TYPE {
    CALLBACK = 'callback',
    REMIND = 'remind',
}


export const MTaskType = new Map<E_TASK_TYPE, string>([
    [ E_TASK_TYPE.CALLBACK, 'Звонок' ],
    [ E_TASK_TYPE.REMIND, 'Напоминание' ],
]);


export const MTaskTypeIcon = new Map<E_TASK_TYPE, TIconName>([
    [ E_TASK_TYPE.CALLBACK, 'phone' ],
    [ E_TASK_TYPE.REMIND, 'calendar-days' ],
]);


export enum SMS_EVENTS {
    CLIENT_CREATED = 'client_created',
    CLIENT_REPAIRED = 'client_repaired',
    CLIENT_DELETED = 'client_deleted',
    CLIENT_STATUS_CHANGED = 'client_status_changed',
    CALL_MISSED = 'call_missed',
}


export const MSmsEvents = new Map<SMS_EVENTS, string>([
    [ SMS_EVENTS.CLIENT_CREATED, 'Клиент создан' ],
    [ SMS_EVENTS.CLIENT_REPAIRED, 'Клиент восстановлен' ],
    [ SMS_EVENTS.CLIENT_DELETED, 'Клиент удалён' ],
    [ SMS_EVENTS.CLIENT_STATUS_CHANGED, 'Изменение статуса клиента' ],
    [ SMS_EVENTS.CALL_MISSED, 'Пропущен звонок' ],
]);


export enum ClientSource {
    REPAIRED = 'repaired',
    CALL = 'call',
    CALL_MANUAL = 'call_manual',
    JIVOSITE = 'jivosite',
    OTHER = 'other',
    REPAIR = 'repair',
    WHATSAPP = 'whatsapp',
    SITE = 'site',
}


export const MClientSources = new Map<ClientSource, string>([
    [ ClientSource.SITE, 'Сайт' ],
    [ ClientSource.CALL, 'Звонок' ],
    [ ClientSource.CALL_MANUAL, 'Звонок ручн.' ],
    [ ClientSource.WHATSAPP, 'Whatsapp' ],
    [ ClientSource.JIVOSITE, 'Jivosite' ],
    [ ClientSource.OTHER, 'Другое' ],
    [ ClientSource.REPAIR, 'Восстановленный' ],
]);


export const MRosreestrCheckListStatus = new Map<RosreestrCheckListStatus, string>([
    [ RosreestrCheckListStatus.NEW, 'Создан' ],
    [ RosreestrCheckListStatus.WAIT, 'Ожидает запуска' ],
    [ RosreestrCheckListStatus.IN_PROGRESS, 'В работе' ],
    [ RosreestrCheckListStatus.PAUSED, 'На паузе' ],
    [ RosreestrCheckListStatus.FINISHED, 'Завершён' ],
    [ RosreestrCheckListStatus.STOPPED, 'Остановлен' ],
]);

export const MRosreestrCheckListStatusIcon = new Map<RosreestrCheckListStatus, TIconName>([
    [ RosreestrCheckListStatus.NEW, 'file' ],
    [ RosreestrCheckListStatus.WAIT, 'clock' ],
    [ RosreestrCheckListStatus.IN_PROGRESS, 'play' ],
    [ RosreestrCheckListStatus.PAUSED, 'pause' ],
    [ RosreestrCheckListStatus.FINISHED, 'flag-checkered' ],
    [ RosreestrCheckListStatus.STOPPED, 'stop' ],
]);

export const MRosreestrCheckListStatusIconColor = new Map<RosreestrCheckListStatus, TUIColor>([
    [ RosreestrCheckListStatus.NEW, 'blue' ],
    [ RosreestrCheckListStatus.WAIT, 'olive' ],
    [ RosreestrCheckListStatus.IN_PROGRESS, 'green' ],
    [ RosreestrCheckListStatus.PAUSED, 'violet' ],
    [ RosreestrCheckListStatus.FINISHED, 'violet' ],
    [ RosreestrCheckListStatus.STOPPED, 'red' ],
]);


export enum UserRole {
    admin = 'admin',
    analytics = 'analytics',
    accountant = 'accountant',
    manager = 'manager',
    creditors_manager = 'creditors_manager',
    additional = 'additional',
    hr = 'hr',
}


export const MUserRole = new Map<UserRole, string>([
    [ UserRole.admin, 'Администратор' ],
    [ UserRole.analytics, 'Аналитик' ],
    [ UserRole.accountant, 'Бухгалтер' ],
    [ UserRole.manager, 'Менеджер' ],
    [ UserRole.creditors_manager, 'Менеджер РК' ],
    [ UserRole.additional, 'Дополнительно' ],
    [ UserRole.hr, 'HR' ],
]);


export enum SOCKET_EVENT {
    NEW_LEAD = 'new_lead',
    NEW_CALL = 'new_call',
    INCOMING_CALL = 'incoming call',
    END_CALL = 'end call',
    ANSWERED_CALL = 'answered call',
    ADDRESS_NOTIFICATION = 'address_notification',
    ABORT_CALL = 'abort_call',

    NOTIFICATION = 'notification',

    SYSTEM_UP = 'system_up',
    SYSTEM_DOWN = 'system_down',
    SYSTEM_FORCE_UPDATE_FRONTEND = 'system_force_update_frontend',
    TRANSIT_LIST_UPDATE = 'transit_list_update',
    TRANSIT_UPDATE = 'transit_update',

    PAUSE_ON = 'pause_on',
    PAUSE_OFF = 'pause_off',
    FINBOX_APPROVE = 'finbox_approve',
    UPDATE_GLOBAL_COUNTERS = 'update_global_counters',
}


export enum UserActiveAction {
    PAUSE_ON = 'pause_on',
    PAUSE_OFF = 'pause_off',
    START_WORK_DAY = 'start_work_day',
    END_WORK_DAY = 'end_work_day',
}


export const MUserActiveAction = new Map<UserActiveAction, string>([
    [ UserActiveAction.PAUSE_ON, 'Пауза включена' ],
    [ UserActiveAction.PAUSE_OFF, 'Пауза выключена' ],
    [ UserActiveAction.START_WORK_DAY, 'Начало рабочего дня' ],
    [ UserActiveAction.END_WORK_DAY, 'Конец рабочего дня' ],
]);

export const MUserActiveActionColor = new Map<UserActiveAction, TUIColor>([
    [ UserActiveAction.PAUSE_ON, 'green' ],
    [ UserActiveAction.PAUSE_OFF, 'orange' ],
    [ UserActiveAction.START_WORK_DAY, 'teal' ],
    [ UserActiveAction.END_WORK_DAY, 'red' ],
]);


export enum SmsStatus {
    NEW = 'new',
    PENDING = 'pending',
    SENDING = 'sending',
    SENT = 'sent',
    DELIVERED = 'delivered',
    FAILED = 'failed',
}


export const MSmsStatus = new Map<SmsStatus, string>([
    [ SmsStatus.NEW, 'Создана' ],
    [ SmsStatus.PENDING, 'Ожидает отправки' ],
    [ SmsStatus.SENDING, 'Отправка' ],
    [ SmsStatus.SENT, 'Отправлена' ],
    [ SmsStatus.DELIVERED, 'Доставлена' ],
    [ SmsStatus.FAILED, 'Ошибка отправки' ],
]);

export const MSmsStatusColor = new Map<SmsStatus, TUIColor>([
    [ SmsStatus.NEW, 'teal' ],
    [ SmsStatus.PENDING, 'yellow' ],
    [ SmsStatus.SENDING, 'orange' ],
    [ SmsStatus.SENT, 'blue' ],
    [ SmsStatus.DELIVERED, 'green' ],
    [ SmsStatus.FAILED, 'red' ],
]);


export enum STATIC_PRODUCT {
    CREDIT = 1,
    LOAN = 2,
    MORTGAGE = 58,
    LEASING = 63,
}

export const diffActionMap = new Map([
    [ 'N', 'Добавление' ],
    [ 'E', 'Изменение' ],
    [ 'D', 'Удаление' ],
    [ 'A', 'Удаление' ],
]);

export enum AdvPlatform {
    YANDEX_DIRECT = 'yandex_direct',
    YANDEX_RSY = 'yandex_rsy',
    YANDEX_MKB = 'yandex_mkb',
    CLICK_RU = 'click.ru',
    VK = 'vk',
    AVITO = 'avito',
}


export const AdvPlatformName = new Map<AdvPlatform, string>([
    [ AdvPlatform.YANDEX_DIRECT, 'Директ' ],
    [ AdvPlatform.YANDEX_RSY, 'РСЯ' ],
    [ AdvPlatform.YANDEX_MKB, 'МКБ' ],
    [ AdvPlatform.CLICK_RU, 'Click.ru' ],
    [ AdvPlatform.VK, 'VK' ],
    [ AdvPlatform.AVITO, 'Avito' ],
]);
